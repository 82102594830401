import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main1_box">
          <div className="main1_box_inner">
            <span>
              Make merry with new deals on PJs, bath, kitchen, games &amp; more.
            </span>
          </div>
        </div>

        <div className="main2_box">
          <div className="main2_box_inner">
            <div className="main2_body">
              <div className="main2_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_663bb044-49ba-44a3-a892-f2720c1ee2f4?wid=1110&qlt=80&fmt=webp"
                />
              </div>
              <div className="main2_body_txt">
                <h3>sleepwear for all*</h3>
              </div>
            </div>
            <div className="main2_body">
              <div className="main2_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_a6111573-4fe3-4c5a-a8cb-d731ca27075d?wid=1110&qlt=80&fmt=webp"
                />
              </div>
              <div className="main2_body_txt">
                <h3>
                  select games, puzzles & <br />
                  activity toys with{" "}
                  <img width="79" height="21" alt="" src="/head/circle.png" />
                </h3>
              </div>
            </div>

            <div className="main2_body1">
              <div className="main2_body1_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_77f97a64-495a-4b76-b646-77663ac38270?wid=720&qlt=80&fmt=webp"
                />
              </div>
              <div className="main2_body_txt">
                <h3>
                  Threshold™ & Casaluna™ <br />
                  bath items*
                </h3>
              </div>
            </div>
            <div className="main2_body1">
              <div className="main2_body1_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_36a916da-096e-4871-8d3e-4d1f4504cff2?wid=720&qlt=80&fmt=webp"
                />
              </div>
              <div className="main2_body_txt">
                <h3>select Christmas trees*</h3>
              </div>
            </div>
            <div className="main2_body1">
              <div className="main2_body1_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_e5cd65b6-a22f-4cc1-aff1-7608bc4ffecd?wid=720&qlt=80&fmt=webp"
                />
              </div>
              <div className="main2_body_txt">
                <h3>kitchen items*</h3>
              </div>
            </div>

            <div className="main2_body2">
              <img
                alt=""
                src="https://target.scene7.com/is/image/Target/GUEST_6d3ebc22-4c99-44fa-8cab-2758b5c51c95?wid=2160&qlt=80&fmt=webp"
              />
              <div className="main2_body2_inner">
                <h2>
                  <span>
                    Hurry! Get&nbsp;
                    <span style={{ color: "#cc0000" }}>50% off</span> your
                    <span class="h-sr-only"> </span>
                    <br aria-hidden="true" /> first year of{" "}
                    <img
                      style={{ height: "30.64px", width: "172px" }}
                      alt=""
                      src="/head/circle1.png"
                    />
                  </span>
                </h2>
                <div className="main2_body2_txt">
                  Enjoy unlimited same-day delivery on orders over $35 & more
                  for <b>just $49 for the first year</b>
                  ** (Reg. $99).
                </div>
                <div className="main2_body2_txt1">
                  {" "}
                  Valid{" "}
                  <span style={{ fontFeatureSettings: "pnum" }}>11/10</span>–
                  <span style={{ fontFeatureSettings: "pnum" }}>12/2</span>.
                  **Terms &amp; conditions apply.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main3_box">
          <div className="main3_box_h2">
            <h2>Top picks for Target Deal Days</h2>
          </div>
          <div className="main3_box_body">
            <div className="main3_body_internal">
              <ul>
                {productData.map((shoe, index) => (
                  <li key={index}>
                    <div className="main3_li_inner">
                      <Link to={`/shopDetails/${shoe.id}`} onClick={toTop}>
                        <img alt="" src={shoe.images[0]} />
                        <div className="main3_item_txt">
                          <div class="main3_price">${shoe.price}</div>
                          <div className="main3_pre_price">
                            reg{" "}
                            <span class="main3-h-text-line-through">
                              ${shoe.prePrice}
                            </span>{" "}
                            <span class="main3-h-text-red">Sale</span>
                          </div>
                          <div class="main3_name">{shoe.name}</div>
                        </div>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="main4_box"></div>

        <div className="main5_box">
          <div className="main5_box_inner">
            <div className="main5_txt">
              <h3>
                <div color="#c00" data-version="v2" class="main5_txt1">
                  <div aria-hidden="true">
                    <span class="main5_txt1_span1">
                      <span>
                        30<small>%</small>
                      </span>
                    </span>
                  </div>
                </div>
              </h3>
              <p class="sc-b8c75e0c-34 fgIqqX">
                <span>
                  <span class="sc-a40744bd-0 UPEKE">
                    Melissa &amp; Doug toys with{" "}
                  </span>
                  <span aria-hidden="false" class="sc-a40744bd-0 UPEKE">
                    <span class="sc-c1278d16-0 hDXUNs">
                      <img
                        style={{ width: "54px", height: "14px" }}
                        alt=""
                        src="/head/circle.png"
                      />
                    </span>
                  </span>
                  <span class="sc-a40744bd-0 UPEKE">
                    <br />
                  </span>
                </span>
              </p>
              <p class="sc-b8c75e0c-38 bWLlsJ amplified-fine-print">
                <span>
                  <span class="sc-a40744bd-0 UPEKE">
                    Today only.
                    <br />
                  </span>
                </span>
              </p>
            </div>
            <div class="main5_img">
              <img
                src="https://target.scene7.com/is/image/Target/GUEST_cdcdd909-0f65-456d-bbde-96fff3240573?fmt=webp&amp;qlt=80&amp;wid=600"
                alt="Target Deal of the Day "
                loading="lazy"
                class="sc-b8c75e0c-21 gKwkLQ"
              />
            </div>
          </div>
          <div className="main5_box_inner">
            <div className="main5_txt">
              <h3>
                <div color="#c00" data-version="v2" class="main5_txt1">
                  <div aria-hidden="true">
                    <span class="main5_txt1_span1">
                      <span>
                        34<small>99</small>
                      </span>
                    </span>
                  </div>
                </div>
              </h3>
              <p class="sc-b8c75e0c-34 fgIqqX">
                <span>
                  <span class="sc-a40744bd-0 UPEKE">after $25 off with </span>
                  <span aria-hidden="false" class="sc-a40744bd-0 UPEKE">
                    <span class="sc-c1278d16-0 hDXUNs">
                      <img
                        style={{ width: "54px", height: "14px" }}
                        alt=""
                        src="/head/circle.png"
                      />
                    </span>
                  </span>
                  <span class="sc-a40744bd-0 UPEKE">
                    , Super Mario RPG - Nintendo Switch. Reg. 59.99
                  </span>
                </span>
              </p>
              <p class="sc-b8c75e0c-38 bWLlsJ amplified-fine-print">
                <span>
                  <span class="sc-a40744bd-0 UPEKE">
                    Today only.
                    <br />
                  </span>
                </span>
              </p>
            </div>
            <div class="main5_img">
              <img
                src="https://target.scene7.com/is/image/Target/GUEST_ae783d4c-6bcf-4e6c-8b8e-6f618f1c5d8c?fmt=webp&qlt=80&wid=600"
                alt="Target Deal of the Day "
                loading="lazy"
                class="sc-b8c75e0c-21 gKwkLQ"
              />
            </div>
          </div>
          <div className="main5_box_inner">
            <div className="main5_txt">
              <h3>
                <div color="#c00" data-version="v2" class="main5_txt1">
                  <div aria-hidden="true">
                    <span class="main5_txt1_span1">
                      <span>
                        25<small>%</small>
                      </span>
                    </span>
                  </div>
                </div>
              </h3>
              <p class="sc-b8c75e0c-34 fgIqqX">
                <span>
                  <span class="sc-a40744bd-0 UPEKE">
                    select Threshold with Studio McGee with{" "}
                  </span>
                  <span aria-hidden="false" class="sc-a40744bd-0 UPEKE">
                    <span class="sc-c1278d16-0 hDXUNs">
                      <img
                        style={{ width: "54px", height: "14px" }}
                        alt=""
                        src="/head/circle.png"
                      />
                    </span>
                  </span>
                  <span class="sc-a40744bd-0 UPEKE">
                    <br />
                  </span>
                </span>
              </p>
              <p class="sc-b8c75e0c-38 bWLlsJ amplified-fine-print">
                <span>
                  <span class="sc-a40744bd-0 UPEKE">
                    Today only.Online only
                    <br />
                  </span>
                </span>
              </p>
            </div>
            <div class="main5_img">
              <img
                src="https://target.scene7.com/is/image/Target/GUEST_997fc787-eb4b-40dc-aed3-b252fcf4bb11?fmt=webp&qlt=80&wid=600"
                alt="Target Deal of the Day "
                loading="lazy"
                class="sc-b8c75e0c-21 gKwkLQ"
              />
            </div>
          </div>
        </div>

        <div className="main6_box"></div>

        <div className="main7_box">
          <h2>Dish up low prices for your feast</h2>
          <div className="main7_inner">
            <div className="main7_inner_body">
              <div className="main7_body_txt">
                <h3>Everything for Thanksgiving</h3>
                <p>Get it all at low prices.</p>
              </div>

              <div className="main7_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_dd1d2e1e-1882-4d0e-af70-d28ca3034257?fmt=webp&qlt=80&wid=600"
                />
              </div>
            </div>
            <div className="main7_inner_body">
              <div className="main7_body_txt">
                <h3>
                  Thanksgiving
                  <br />
                  meal for 4
                  <br />
                  under
                  <br />
                  <span style={{ fontSize: "2.65em" }}>$5</span>
                </h3>
                <p>per person.</p>
              </div>

              <div className="main7_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_4aa6c1f1-aed9-4ca6-9928-160804c9f8be?fmt=webp&qlt=80&wid=600"
                />
              </div>
            </div>{" "}
            <div className="main7_inner_body">
              <div className="main7_body_txt">
                <h3>
                  Thanksgiving
                  <br />
                  baking
                  <br />
                  from
                  <br />
                  <span style={{ fontSize: "2.65em" }}>$2</span>
                </h3>
              </div>

              <div className="main7_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_5899770b-969e-45ab-a422-2ef941d7e841?fmt=webp&qlt=80&wid=600"
                />
              </div>
            </div>{" "}
            <div className="main7_inner_body">
              <div className="main7_body_txt">
                <h3>
                  Thanksgiving
                  <br />
                  meal for 4
                  <br />
                  sides
                  <br />
                  <span style={{ fontSize: "2.65em" }}>$5</span>
                </h3>
                <p>per person.</p>
              </div>

              <div className="main7_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_1160055a-441b-4cb7-84af-37d8d74cc590?fmt=webp&qlt=80&wid=600"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main8_box">
          <div className="main8_box_inner">
            <h2>
              <span>
                Turkey & all the fixins’ to your door with
                <img
                  alt=""
                  src="/head/circle1.png"
                  style={{ width: "172px", height: "30.64px" }}
                />
              </span>
            </h2>
            <div className="main8_txt1">
              Get unlimited same-day delivery on orders over $35 when you
              subscribe.
            </div>
          </div>
        </div>

        <div className="main9_box"></div>

        <div className="main10_box">
          <h2>Wish list winners</h2>
          <div className="main10_box_inner">
            <div className="main10_body">
              <div className="main10_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_67a9df66-b6d8-4ac0-80ec-acd8e77c45ff?fmt=webp&qlt=80&wid=600"
                />
              </div>
              <div className="main10_body_txt">
                <h3>Caitlin Clark Journey Basketball Series</h3>
                <p>
                  Surprise a fan with this <span>$19.99</span>pick.
                </p>
              </div>
            </div>{" "}
            <div className="main10_body">
              <div className="main10_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_0cf399c2-d7c5-4464-a93c-d4cce559709e?fmt=webp&qlt=80&wid=600"
                />
              </div>
              <div className="main10_body_txt">
                <h3>New & exclusive for the holidays</h3>
                <p>
                  The 24" Bullseye plush is ready for all the cuddles & only
                  $25.
                </p>
                <small>Quantities limited.</small>
              </div>
            </div>{" "}
            <div className="main10_body">
              <div className="main10_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_24af3cba-0d36-4e35-be46-4f28d365331b?fmt=webp&qlt=80&wid=600"
                />
              </div>
              <div className="main10_body_txt">
                <h3>Check out new stories from Tonies</h3>
                <p>
                  Explore the latest screen-free audio adventures for kiddos.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="main11_box">
          <h2>Featured categories</h2>
          <p>Shop all</p>
          <div className="main11_box_inner">
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_54799b5f-ffca-4db9-b4b2-bf401ef3a4f0?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Thanksgiving</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_26844426-7c2d-404c-8815-53a193fe3fed?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Christmas</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_39095455-b608-4fae-ad8d-1f2a6bcca2b3?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Hanukkah</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_df2aa5c5-50a8-408a-ac02-a559ed605377?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Women’s</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_6523a5dc-c592-4202-b0a0-fe07dfa5face?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Men’s</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_b46add15-a6b4-465c-ae80-8c9e1a96fe23?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Kids’</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_ac803e04-bcbf-452a-b6a4-03aab0ae3062?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Baby</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/Furniture-210913-1631566212856?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Home</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_a3253fb8-4cd7-42c6-b0d4-fcfee00fab40?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Electronics</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/KitchenDining-210913-1631564122569?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Kitchen & Dining</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/Sept_Wk5-0930-Video_Games-Nintendo-CB-Consoles143381-180910_1536591280324?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Video Games</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_fce2da4c-a3dc-4007-9e66-37530f70f9c6?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Apple</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/GUEST_342aa225-dde3-4eb6-b915-f2025a581bb9?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Toys</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/Sports_Outdoors219475-200305_1583425943604?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Sports & Outdoors</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/Grocery219475-200305_1583423555572?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Grocery</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/Beauty181103-190722_1563828945271?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Beauty</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/HouseholdEssentials_Tide-200519-1589915606672?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Household Essentials</span>
              </div>
            </div>
            <div className="main11_body">
              <div className="main11_body_img">
                <img
                  alt=""
                  src="https://target.scene7.com/is/image/Target/clearance97188-171020_1508525411214?wid=315&hei=315&qlt=60&fmt=webp"
                />
              </div>
              <div className="main11_body_txt">
                <span>Clearance</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
