import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import "../css/cartpage.css";
import { useNavigate, Link } from "react-router-dom";

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };
  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        total += price * item.quantity || price * 1;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, itemSize, itemColor, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const item = cartItems.find(
      (cartItem) =>
        cartItem.id === itemId &&
        cartItem.size === itemSize &&
        cartItem.color === itemColor
    );
    if (!item) return 0;
    const price = parseFloat(product.price);
    return price * quantity || price;
  };

  const handleInputChange = (id, event, size, color) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity, size || undefined, color || undefined);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart_box">
          {cartItems.length === 0 ? (
            <h3 style={{ color: "black", textAlign: "center" }}>
              Your Cart is Empty!
            </h3>
          ) : (
            <>
              <div className="cart_box_inner">
                <div className="cart_box_inner_left">
                  <h1>Your cart</h1>
                  <div className="cart_body">
                    <table>
                      <tbody>
                        {cartItems.map((item, index) => {
                          const product = getProductById(item.id);
                          const itemTotal = calculateItemTotal(
                            item.id,
                            item.size,
                            item.color,
                            item.quantity
                          );

                          return (
                            <tr key={index}>
                              <td
                                className="cart-item__image"
                                style={{ position: "relative" }}
                              >
                                <img alt="" src={product.images[0]} />
                              </td>
                              <td className="cart-item__details">
                                <Link
                                  className="cart-item__title"
                                  to={`/shopDetails/${product.id}`}
                                >
                                  {product.name}
                                </Link>
                                <div className="cart-item__info">
                                  <strong className="price__current">
                                    ${product.price}
                                  </strong>
                                  <div className="cart-item__variants text-sm">
                                    <dl className="mt-2 mb-0">
                                      {item.size && (
                                        <div>
                                          <dt className="inline">
                                            <strong>Size: </strong>
                                          </dt>
                                          <dd className="inline m-0">
                                            {item.size}
                                          </dd>
                                        </div>
                                      )}
                                      {item.color && (
                                        <div>
                                          <dt className="inline">
                                            <strong>Color: </strong>
                                          </dt>
                                          <dd className="inline m-0">
                                            {item.color}
                                          </dd>
                                        </div>
                                      )}
                                    </dl>
                                  </div>
                                </div>
                              </td>

                              <td className="cart-item__total price cart_three">
                                <strong className="price__current">
                                  ${itemTotal * item.quantity}
                                </strong>
                                <div
                                  class="h-text-md h-text-grayDark"
                                  data-test="item-regular-price"
                                >
                                  <span aria-hidden="true">
                                    reg{" "}
                                    <s data-test="strikethrough-price">
                                      ${product.prePrice}
                                    </s>
                                  </span>
                                </div>
                                <p
                                  class="sc-dc0c335c-0 gSnIvi h-text-md h-text-red h-margin-b-tiny"
                                  data-test="item-current-price-type"
                                >
                                  Sale&nbsp;
                                  {(product.prePrice - product.price) *
                                    item.quantity}
                                </p>

                                <div className="cart_qty_select">
                                  <select
                                    value={item.quantity}
                                    onChange={(e) =>
                                      handleInputChange(
                                        item.id,
                                        e,
                                        item.size,
                                        item.color
                                      )
                                    }
                                  >
                                    {Array.from({ length: 100 }, (_, i) => (
                                      <option key={i + 1} value={i + 1}>
                                        {i + 1}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="cart_qty_abi">Quantity</div>
                                </div>
                                <button
                                  type="button"
                                  class="cart_remove_button"
                                  onClick={() =>
                                    removeFromCart(
                                      item.id,
                                      item.size,
                                      item.color
                                    )
                                  }
                                >
                                  <span class="cart_remove_button_span">
                                    <svg
                                      focusable="false"
                                      viewBox="2 2 24 24"
                                      class="pl-BaseIcon BaseIcon pl-BaseIcon--scalable"
                                      aria-hidden="true"
                                      data-hb-id="pl-icon"
                                    >
                                      <path d="M22 8.5h-4.25v-.26a3.75 3.75 0 00-7.5 0v.26H6a.5.5 0 000 1h1.5V23a.5.5 0 00.5.5h12a.5.5 0 00.5-.5V9.5H22a.5.5 0 000-1zm-10.75-.26a2.75 2.75 0 015.5 0v.26h-5.5zM15.5 9.5v13h-3v-13zm-7 0h3v13h-3zm11 13h-3v-13h3z"></path>
                                    </svg>
                                    Remove
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="cart_box_inner_right">
                  <div className="cart__summary-wrapper">
                    <div className="summary_top_flex">
                      <h3>Order Summary (29)</h3>
                      <div class="cart_savings--section">
                        <strong class="text-sm">(1) Item:</strong>
                        <strong class="text-sm">${calculateTotal()}</strong>
                      </div>
                    </div>
                    <div class="rte">
                      Taxes and shipping calculated at checkout.
                    </div>
                    <div class="summary_total_price">
                      <h3>Subtotal:</h3>
                      <p>${calculateTotal()}</p>
                    </div>
                    <div class="mt-6">
                      <button
                        class="btn btn--primary_check btn--icon-with-text w-full"
                        name="checkout"
                        onClick={toCheckOut}
                      >
                        Sign in to check out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CartPage;
