import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [activeIndex, setActiveIndex] = useState(null);
  const [quantities, setQuantities] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const toggleDisplay = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleAddToCart = () => {
    if (product.size.length > 0 && !selectedSize) {
      alert("Please select a size!");
      return;
    }
    if (product.color.length > 0 && !selectedColor) {
      alert("Please select a color!");
      return;
    }

    const options = {
      size: selectedSize || null,
      color: selectedColor || null,
    };

    addToCart(product.id, quantities, options.size, options.color);
    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div
        className="mainBox"
        style={{
          borderBottom: "1px solid rgb(37 37 37 / 15%)",
        }}
      >
        <div className="detail_box">
          <div className="iphone_nav">
            <nav>
              <ol>
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <img
                    width="24"
                    height="24"
                    src="https://img.icons8.com/material-sharp/50/forward.png"
                    alt="forward"
                  />
                </li>
                <li>{product.name}</li>
              </ol>
            </nav>
          </div>

          <div className="item_main_box">
            <div className="item_main_box_left">
              <div className="product-images">
                <div className="carousel">
                  {product.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`${product.name}`}
                      style={{
                        display: index === currentIndex ? "block" : "none",
                      }}
                    />
                  ))}
                </div>
                <div className="carousel-buttons">
                  {product.images.map((image, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentIndex(index)}
                      style={{
                        border:
                          currentIndex === index
                            ? "2px solid #7b189f"
                            : "2px solid transparent",
                      }}
                    >
                      <img src={image} alt={`${product.name}`} />
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="item_main_box_right">
              <div className="item_main_box_right_inner">
                <h1>{product.name}</h1>
                <div className="rating_review">
                  <span className="ProductRatingNumberWithCount-rating">
                    4.6
                  </span>
                  <a className="item_reviews" alt="" href="/cartpage">
                    667 Reviews
                  </a>
                </div>
                <div className="item_right_price">
                  <div>
                    <span class="item-right-h-text-red">
                      <span class="product-price-inner">${product.price}</span>
                    </span>
                    <span
                      class="h-product-regular-price"
                      data-test="product-regular-price"
                    >
                      {" "}
                      reg{" "}
                      <span class="h-text-line-through">
                        ${product.prePrice}
                      </span>
                    </span>
                    <div class="h-text-md">
                      <span
                        class="h-text-red h-text-bold h-text-md"
                        data-test="product-offer-type"
                      >
                        Sale&nbsp;
                      </span>
                      <span
                        class="h-text-grayDark h-text-normal h-text-sm "
                        data-test="product-savings-amount"
                      >
                        save ${product.prePrice - product.price} ({product.sale}{" "}
                        off)
                      </span>
                    </div>
                  </div>
                </div>
                {product.size.length > 0 && (
                  <div className="item_right_size">
                    <label>Size</label>
                    <select
                      value={selectedSize}
                      onChange={(e) => setSelectedSize(e.target.value)}
                    >
                      <option value="">Please select the size</option>
                      {product.size.map((size, index) => (
                        <>
                          <option key={index} value={size}>
                            {size}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                )}
                {product.color.length > 0 && (
                  <div className="item_right_size">
                    <label>Color</label>
                    <select
                      value={selectedColor}
                      onChange={(e) => setSelectedColor(e.target.value)}
                    >
                      <option value="">Please select the color</option>
                      {product.color.map((color, index) => (
                        <>
                          <option key={index} value={color}>
                            {color}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                )}
                <div className="item_right_quanty_cart">
                  <div className="item_right_quanty">
                    <select
                      value={quantities}
                      min="1"
                      onChange={(e) => setQuantities(e.target.value)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <div className="item_right_add">
                    <button
                      className="btn btn--primary w-full"
                      name="add"
                      onClick={() => handleAddToCart()}
                    >
                      Add to cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about_item_details">
            <h2>About this item</h2>
            <div className="about_des_all">
              <div className="item_details">
                <div className="item_details_inner">
                  <div className="item_details_internal">
                    <span className="disclosure__toggle">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon"
                        onClick={() => toggleDisplay(1)}
                        style={{
                          transform:
                            activeIndex === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      >
                        <path
                          d="M20 8.5 12.5 16 5 8.5"
                          stroke="currentColor"
                          stroke-width="1.5"
                          fill="none"
                        ></path>
                      </svg>
                    </span>
                    <h2 style={{ marginBottom: "0", fontSize: "19px" }}>
                      Description
                    </h2>
                  </div>
                  <div
                    className="item_details_body"
                    style={{
                      display: activeIndex === 1 ? "block" : "none",
                    }}
                  >
                    <p style={{ marginTop: "2em" }}>
                      {product.description.main}
                    </p>
                    <p>
                      <br />
                    </p>
                    {product.description.features > 0 && (
                      <>
                        <p>
                          <strong>Features:&nbsp;</strong>
                        </p>
                        <ul>
                          {product.description.features.map(
                            (feature, index) => (
                              <li key={index}>{feature}</li>
                            )
                          )}
                        </ul>
                        <p>
                          <br />
                        </p>
                      </>
                    )}

                    {product.description.inclued > 0 && (
                      <>
                        <p>
                          <strong>Include: &nbsp;</strong>
                        </p>
                        <ul>
                          {product.description.inclued.map((inclued, index) => (
                            <li key={index}>{inclued}</li>
                          ))}
                        </ul>{" "}
                        <p>
                          <br />
                        </p>
                      </>
                    )}

                    {product.description.purposeful > 0 && (
                      <>
                        <p>
                          <strong>Purposeful: &nbsp;</strong>
                        </p>
                        <ul>
                          {product.description.purposeful.map(
                            (purposeful, index) => (
                              <li key={index}>{purposeful}</li>
                            )
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="item_details_inner"
                  style={{ marginBottom: "2rem" }}
                >
                  <div className="item_details_internal">
                    <span className="disclosure__toggle">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon"
                        onClick={() => toggleDisplay(2)}
                        style={{
                          transform:
                            activeIndex === 2
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      >
                        <path
                          d="M20 8.5 12.5 16 5 8.5"
                          stroke="currentColor"
                          stroke-width="1.5"
                          fill="none"
                        ></path>
                      </svg>
                    </span>
                    <h2 style={{ marginBottom: "0", fontSize: "19px" }}>
                      Specifications
                    </h2>
                  </div>
                  <div
                    className="item_details_body"
                    style={{
                      display: activeIndex === 2 ? "block" : "none",
                      marginTop: "2em",
                    }}
                  >
                    {product.specification.Dimensions && (
                      <div className="item_body_flex">
                        <div className="left-col">Dimensions</div>
                        <div className="right-col">
                          {product.specification.Dimensions}
                        </div>
                      </div>
                    )}
                    {product.specification.Style && (
                      <div className="item_body_flex">
                        <div className="left-col">Style</div>
                        <div className="right-col">
                          {product.specification.Style}
                        </div>
                      </div>
                    )}
                    {product.specification.Weight && (
                      <div className="item_body_flex">
                        <div className="left-col">Weight</div>
                        <div className="right-col">
                          {product.specification.Weight}
                        </div>
                      </div>
                    )}
                    {product.specification.ProtectiveQualities && (
                      <div className="item_body_flex">
                        <div className="left-col">Protective Qualities</div>
                        <div className="right-col">
                          {product.specification.ProtectiveQualities}
                        </div>
                      </div>
                    )}
                    {product.specification.TextileMaterial && (
                      <div className="item_body_flex">
                        <div className="left-col">Textile Material</div>
                        <div className="right-col">
                          {product.specification.TextileMaterial}
                        </div>
                      </div>
                    )}
                    {product.specification.Includes && (
                      <div className="item_body_flex">
                        <div className="left-col">Includes</div>
                        <div className="right-col">
                          {product.specification.Includes}
                        </div>
                      </div>
                    )}
                    {product.specification.HangingStyle && (
                      <div className="item_body_flex">
                        <div className="left-col">Hanging Style</div>
                        <div className="right-col">
                          {product.specification.HangingStyle}
                        </div>
                      </div>
                    )}
                    {product.specification.FabricWeightType && (
                      <div className="item_body_flex">
                        <div className="left-col">Fabric Weight Type</div>
                        <div className="right-col">
                          {product.specification.FabricWeightType}
                        </div>
                      </div>
                    )}
                    {product.specification.NumbersofPiece && (
                      <div className="item_body_flex">
                        <div className="left-col">Number of Pieces</div>
                        <div className="right-col">
                          {product.specification.NumbersofPiece}
                        </div>
                      </div>
                    )}
                    {product.specification.Sizing && (
                      <div className="item_body_flex">
                        <div className="left-col">Sizing</div>
                        <div className="right-col">
                          {product.specification.Sizing}
                        </div>
                      </div>
                    )}
                    {product.specification.Piece1 && (
                      <div className="item_body_flex">
                        <div className="left-col">Piece 1</div>
                        <div className="right-col">
                          {product.specification.Piece1}
                        </div>
                      </div>
                    )}
                    {product.specification.Piece1Material && (
                      <div className="item_body_flex">
                        <div className="left-col">Piece 1 Material</div>
                        <div className="right-col">
                          {product.specification.Piece1Material}
                        </div>
                      </div>
                    )}
                    {product.specification.Piece1GarmentLength && (
                      <div className="item_body_flex">
                        <div className="left-col">Piece 1 Garment Length</div>
                        <div className="right-col">
                          {product.specification.Piece1GarmentLength}
                        </div>
                      </div>
                    )}
                    {product.specification.Piece2 && (
                      <div className="item_body_flex">
                        <div className="left-col">Piece 2</div>
                        <div className="right-col">
                          {product.specification.Piece2}
                        </div>
                      </div>
                    )}
                    {product.specification.Piece2Material && (
                      <div className="item_body_flex">
                        <div className="left-col">Piece 2 Material</div>
                        <div className="right-col">
                          {product.specification.Piece2Material}
                        </div>
                      </div>
                    )}
                    {product.specification.Piece2GarmentLength && (
                      <div className="item_body_flex">
                        <div className="left-col">Piece 2 Garment Length</div>
                        <div className="right-col">
                          {product.specification.Piece2GarmentLength}
                        </div>
                      </div>
                    )}
                    {product.specification.Piece2GarmentClosure && (
                      <div className="item_body_flex">
                        <div className="left-col">Piece 2 Garment Closure</div>
                        <div className="right-col">
                          {product.specification.Piece2GarmentClosure}
                        </div>
                      </div>
                    )}
                    {product.specification.Fit && (
                      <div className="item_body_flex">
                        <div className="left-col">Fit</div>
                        <div className="right-col">
                          {product.specification.Fit}
                        </div>
                      </div>
                    )}
                    {product.specification.SleeveLength && (
                      <div className="item_body_flex">
                        <div className="left-col">Sleeve Length</div>
                        <div className="right-col">
                          {product.specification.SleeveLength}
                        </div>
                      </div>
                    )}
                    {product.specification.FabricName && (
                      <div className="item_body_flex">
                        <div className="left-col">Fabric Name</div>
                        <div className="right-col">
                          {product.specification.FabricName}
                        </div>
                      </div>
                    )}
                    {product.specification.Neckline && (
                      <div className="item_body_flex">
                        <div className="left-col">Neckline</div>
                        <div className="right-col">
                          {product.specification.Neckline}
                        </div>
                      </div>
                    )}
                    {product.specification.GarmentDetails && (
                      <div className="item_body_flex">
                        <div className="left-col">Garment Details</div>
                        <div className="right-col">
                          {product.specification.GarmentDetails}
                        </div>
                      </div>
                    )}
                    {product.specification
                      .IndustryorGovernmentCertifications && (
                      <div className="item_body_flex">
                        <div className="left-col">
                          Industry or Government Certifications
                        </div>
                        <div className="right-col">
                          {
                            product.specification
                              .IndustryorGovernmentCertifications
                          }
                        </div>
                      </div>
                    )}
                    {product.specification.Piece1garmentclosuretype && (
                      <div className="item_body_flex">
                        <div className="left-col">
                          Piece 1 Garment Closure Type
                        </div>
                        <div className="right-col">
                          {product.specification.Piece1garmentclosuretype}
                        </div>
                      </div>
                    )}
                    {product.specification.OEKOTEXInstitution && (
                      <div className="item_body_flex">
                        <div className="left-col">OEKO-TEX Institution</div>
                        <div className="right-col">
                          {product.specification.OEKOTEXInstitution}
                        </div>
                      </div>
                    )}
                    {product.specification.OEKOTEXCertificationNumber && (
                      <div className="item_body_flex">
                        <div className="left-col">
                          OEKO-TEX Certification Number
                        </div>
                        <div className="right-col">
                          {product.specification.OEKOTEXCertificationNumber}
                        </div>
                      </div>
                    )}
                    {product.specification.CareandCleaning && (
                      <div className="item_body_flex">
                        <div className="left-col">Care and Cleaning</div>
                        <div className="right-col">
                          {product.specification.CareandCleaning}
                        </div>
                      </div>
                    )}
                    {product.specification.TCIN && (
                      <div className="item_body_flex">
                        <div className="left-col">TCIN</div>
                        <div className="right-col">
                          {product.specification.TCIN}
                        </div>
                      </div>
                    )}
                    {product.specification.UPC && (
                      <div className="item_body_flex">
                        <div className="left-col">UPC</div>
                        <div className="right-col">
                          {product.specification.UPC}
                        </div>
                      </div>
                    )}
                    {product.specification.ItemNumber && (
                      <div className="item_body_flex">
                        <div className="left-col">Item Number</div>
                        <div className="right-col">
                          {product.specification.ItemNumber}
                        </div>
                      </div>
                    )}
                    {product.specification.Origin && (
                      <div className="item_body_flex">
                        <div className="left-col">Origin</div>
                        <div className="right-col">
                          {product.specification.Origin}
                        </div>
                      </div>
                    )}
                    {product.specification.FlameResistant && (
                      <div className="item_body_flex">
                        <div className="left-col">Flame Resistant</div>
                        <div className="right-col">
                          {product.specification.FlameResistant}
                        </div>
                      </div>
                    )}
                    {product.specification.AdultGarment && (
                      <div className="item_body_flex">
                        <div className="left-col">Adult Garment</div>
                        <div className="right-col">
                          {product.specification.AdultGarment}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProductDetail;
