import "../css/header.css";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const { cartItems } = useContext(CartContext);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="header-container">
        <div className="header_top">
          <div className="header_top_inner">
            <div className="header_ship">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="20"
                width="14"
                fill="white"
              >
                <path d="M12 2C8.13 2 5 5.13 5 9c0 4.42 5.45 10.74 6.69 12.09a1.5 1.5 0 0 0 2.31 0C13.55 19.74 19 13.42 19 9c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z" />
              </svg>
              <span
                data-test="@web/ZipCodeButton/ZipCodeNumber"
                class="sc-207096e0-6 dbOEbf"
              >
                Ship to 433 Canyon Rd. Sundance, WY 82729
              </span>
            </div>
            <div className="header_ship">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="20"
                width="14"
                fill="white"
              >
                <path d="M5 7H4a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1v2.18c0 .53-.21 1.04-.59 1.41l-5 5V21a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-5.41l-5-5A2 2 0 0 1 5 9.18V7zm5 14h4v-4h-4v4z" />
              </svg>

              <span
                data-test="@web/ZipCodeButton/ZipCodeNumber"
                class="sc-207096e0-6 dbOEbf"
              >
                Santa Clarita East
              </span>
            </div>
          </div>
        </div>

        <div className="header_mid">
          <div className="header_mid_inner">
            <div className="header_mid_left">
              <Link to="/" onClick={toTop}>
                <img width="48" height="48" alt="" src="/head/title.png" />
              </Link>
            </div>

            <div className="header_mid_mid">
              <ul>
                <li>
                  <Link to="/" onClick={toTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toTop}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" onClick={toTop}>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>

            <div className="header_mid_right">
              <Link to="/cartpage" onClick={toTop}>
                <div className="header_right_cart_page">
                  <div class="zwpau96">
                    <sup
                      class="ssk87c1"
                      data-enzyme-id="CartContentBadgeQuantity"
                    >
                      {cartItems.length}
                    </sup>
                    <svg
                      focusable="false"
                      viewBox="2 2 24 24"
                      __typename="HomebaseIcon"
                      logger="[object Object]"
                      aria-hidden="true"
                      data-hb-id="pl-icon"
                      data-enzyme-id="HomebaseIcon"
                      class="_1fr089m0 _1fr089m1 zwpau97"
                    >
                      <path d="M21 15.54a.51.51 0 00.49-.38l2-8a.51.51 0 00-.1-.43.49.49 0 00-.39-.19H8.28L8 4.9a.51.51 0 00-.49-.4H5a.5.5 0 000 1h2.05L9 15l-2.36 4.74a.53.53 0 000 .49.5.5 0 00.42.23H21a.5.5 0 00.5-.5.5.5 0 00-.5-.5H7.89l1.92-3.92zm1.34-8l-1.73 7H9.92l-1.43-7zM10 21a1 1 0 101 1 1 1 0 00-1-1zM18 21a1 1 0 101 1 1 1 0 00-1-1z"></path>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
