import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  const toBottom = () => {
    window.scrollTo({
      bottom: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <div className="privacy_top">
            <h1>Target Privacy Policy</h1>
            <div className="privacy_top_ol">
              <ol>
                <li class="styles_listItem__XQB0p">
                  <Link to="/">Target</Link>
                </li>
                <li class="styles_listItem__XQB0p">
                  <span
                    aria-current="page"
                    data-test="@web/Breadcrumbs/BreadcrumbLink"
                  >
                    Target Privacy Policy
                  </span>
                </li>
              </ol>
            </div>
          </div>

          <div className="privacy_mid">
            <div className="privacy_mid_inner">
              <h2>Target Privacy Policy</h2>
              <p>
                At Target, we want you to know how we collect, use, share, and
                protect information about you. By interacting with Target
                through our stores, websites, mobile application(s), products,
                services, or otherwise, you consent to the use of information
                that is collected or submitted as described in this privacy
                policy. We may change or add to this privacy policy, so we
                encourage you to review it periodically.
              </p>
            </div>
          </div>

          <div className="privacy_body_top">
            <div className="privacy_body_top_inner">
              <a href="#Type">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    1){" "}
                    <span style={{ textDecoration: "underline" }}>
                      Types of Information Collected
                    </span>
                    <br aria-hidden="true" />
                  </span>
                </span>
              </a>
              <a href="#InfoCollection">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    2){" "}
                    <span style={{ textDecoration: "underline" }}>
                      How is Your Information Collected?
                    </span>
                  </span>
                </span>
              </a>
              <a href="#InfoUsed">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    3){" "}
                    <span style={{ textDecoration: "underline" }}>
                      How is Your Information Used?
                    </span>
                  </span>
                </span>
              </a>
              <a href="#InfoShared">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    4){" "}
                    <span style={{ textDecoration: "underline" }}>
                      How is Your Information Shared?
                    </span>
                  </span>
                </span>
              </a>
              <a href="#Choices">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    5){" "}
                    <span style={{ textDecoration: "underline" }}>
                      What Choices Do You Have?
                    </span>
                  </span>
                </span>
              </a>
              <a href="#FIP">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    6){" "}
                    <span style={{ textDecoration: "underline" }}>
                      Loyalty &amp; Financial Incentive Program
                    </span>
                  </span>
                </span>
              </a>
              <a href="#State-Specific Privacy Information">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    7){" "}
                    <span style={{ textDecoration: "underline" }}>
                      State-Specific Privacy Information
                    </span>
                  </span>
                </span>
              </a>
              <a href="#AccessingPersonalInformation">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    8){" "}
                    <span style={{ textDecoration: "underline" }}>
                      How do You Access and Update Your Personal Information?
                    </span>
                  </span>
                </span>
              </a>
              <a href="#PersonalInformationSecurity">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    9){" "}
                    <span style={{ textDecoration: "underline" }}>
                      How is Your Personal Information Protected?
                    </span>
                  </span>
                </span>
              </a>
              <a href="#AutomatedLicensePlate">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    10){" "}
                    <span style={{ textDecoration: "underline" }}>
                      Automated License Plate Recognition Technology
                    </span>
                  </span>
                </span>
              </a>
              <a href="#ContactTarget">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    11){" "}
                    <span style={{ textDecoration: "underline" }}>
                      How do you contact Target?
                    </span>
                  </span>
                </span>
              </a>
              <a href="#TargetPPS">
                <span style={{ lineHeight: "50%", display: "block" }}>
                  <span>
                    12){" "}
                    <span style={{ textDecoration: "underline" }}>
                      Target Privacy Policy Scope
                    </span>
                  </span>
                </span>
              </a>
            </div>
          </div>

          <div className="privacy_body">
            <div className="privacy_body_txt">
              <h3>1. Types of information Collected:</h3>
              <dl class="h-text-md">
                <dd>
                  <span>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>Name</span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Mailing address
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>Email address</span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Phone (or mobile) number
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Driver’s license number
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Credit/debit card number
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Purchase/return/exchange information
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Registry or list information
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Your mobile device information (e.g., device model,
                          operating system version, device date and time, unique
                          device identifiers, mobile network information)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          How you interact with our sites, mobile applications,
                          advertisements and emails (e.g., clicks, search terms,
                          pages visited, usage details such as time, length, and
                          frequency of interactions){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Geo-location and in-store location{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Media Information (e.g., advertising engagement in
                          social, digital, and broadcast media){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>IP Address </span>{" "}
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Special status designations (e.g., Teacher, College
                          Student, Military){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Language Preferences
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Demographics (e.g., age, sex, race, date of birth,
                          education, occupation){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Health Data (e.g., in connection with Guest service or
                          product development)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Physical Characteristics (e.g., in connection with
                          makeup/skincare)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Images (e.g., when you add a profile picture or upload
                          photos/videos)
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you choose not to provide information, we may not be
                        able to provide you with requested products, services or
                        information.
                      </span>
                    </p>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <a
                          href="#"
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Back to top
                        </a>
                      </span>
                    </p>

                    <div
                      style={{
                        height: ".25px",
                        marginTop: "1rem",
                        backgroundColor: "#333333",
                      }}
                    ></div>
                  </span>
                </dd>
              </dl>
            </div>
            <div className="privacy_body_txt">
              <h3>2. How Is Your Information Collected?</h3>
              <dl class="h-text-md">
                <dt>
                  <span style={{ fontSize: "120%" }}>
                    <h4>You Provide It to Us</h4>
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We collect information that you enter on our digital
                        properties or give to us in other ways (e.g., when you
                        contact Guest Relations, order on Target.com, purchase a
                        product in a Target store or create a gift registry).
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <span style={{ fontSize: "120%" }}>
                    <h4>Automated Information Collection</h4>
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may connect information collected automatically with
                        information we already have about you in order to
                        identify you as a Target Guest. If we are able to
                        identify you as a Target Guest we may, for example, link
                        your activity on our website to your activity in a
                        Target store or on one of our mobile application(s).
                        This allows us to provide you with a personalized
                        experience regardless of how you interact with us –
                        online, in store, mobile, etc.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        In order to provide the best Guest experience possible
                        and run our business operations, we also use automated
                        information collection technologies for reporting and
                        analysis purposes. We examine metrics such as how our
                        marketing efforts are performing and how you are
                        interacting on our website, in our stores, on our mobile
                        application(s), and with our advertisements and emails.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <h4>Third-party Automated Collection</h4>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We and our service providers use anonymous identifiers
                        such as cookies and other technologies to collect and
                        store certain types of information (e.g., click stream
                        information, browser type, time and date, subject of
                        advertisements clicked or scrolled over,
                        hardware/software information, cookie and session ID)
                        whenever you interact with us or third-parties that use
                        our services.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We also allow third-party companies (e.g., Google) to
                        place tags on our digital properties once approved
                        through our tagging process. The tags may collect
                        information from your interactions on Target.com. Our
                        Privacy Policy does not cover these third party
                        companies. Please contact these third-party companies
                        (e.g., Google) directly for more information about their
                        privacy policy and your choices regarding the tags and
                        the information collected by the tags.{" "}
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <span style={{ fontSize: "120%" }}>
                    <h4>Social Media Widgets</h4>
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Our sites and mobile application(s) include social media
                        features, such as the Facebook, Pinterest, and Twitter
                        widgets. These features may collect information about
                        you such as your IP address and which page you are
                        visiting on our site. They may set a cookie or employ
                        other tracking technologies to accomplish this. Social
                        media features and widgets may be hosted by a
                        third-party. Your interactions with those features are
                        governed by the privacy policies of the companies that
                        provide them.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <span style={{ fontSize: "120%" }}>
                    <h4>Mobile Location Information</h4>
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you use a mobile device, your device may share
                        location information (when you enable location services)
                        with our websites, mobile application(s), services or
                        our service providers. For example, precise geo-location
                        can be used to help you find nearby Target stores; or,
                        in-store location through the use of your phone’s blue
                        tooth signal, LED light chip technologies or other
                        technologies will permit Target to find nearby products
                        for you, get you real-time deals, auto-sort your
                        shopping list and more.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <h4>
                          {" "}
                          To manage how you share your device’s location
                          settings, go to the Choices section of this privacy
                          policy.
                        </h4>
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <span style={{ fontSize: "120%" }}>
                    <h4>Cameras</h4>
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We use cameras in and around our stores for operational
                        purposes such as measuring traffic patterns and tracking
                        in-stock levels. Cameras may also be used for fraud
                        detection, theft prevention and security. When enabled,
                        we may also use your device’s camera (e.g., Apple’s
                        TrueDepth camera) so you can use services such as
                        product visualization (e.g., see item in your space) or
                        virtual try on services (e.g., try on accessories/beauty
                        products). Information collected from your device’s
                        camera for these services is used in real time and is
                        not stored on the servers. The virtual try on experience
                        operates within your own device or browser and the data
                        is deleted after the experience ends.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <span style={{ fontSize: "120%" }}>
                    <h4>Information From Other Sources</h4>
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We collect information about you from other sources to
                        enhance our existing Guest information and improve the
                        accuracy of our records. This improves our ability to
                        contact you and increases the relevance of our
                        marketing.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <a
                          href="#"
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Back to Top
                        </a>
                      </span>
                    </p>

                    <div
                      style={{
                        height: ".25px",
                        marginTop: "1rem",
                        backgroundColor: "#333333",
                      }}
                    ></div>
                  </span>
                </dd>
              </dl>
            </div>
            <div className="privacy_body_txt">
              <h3>3. How Is Your Information Used?</h3>
              <dl class="h-text-md">
                <dt></dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Information we collect is used for a variety of
                        purposes, including activities such as: processing
                        transactions, helping our Guest Relations
                        representatives resolve issues, identifying fraud and
                        improving our services. In addition, information we
                        collect improves your experience by delivering more
                        personalized interactions and advertising in digital
                        (e.g., Target.com, mobile app, other online sites) and
                        social media (e.g., Facebook, Pinterest), as well as TV
                        and Video platforms.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We also use information we collect for reporting and
                        analysis purposes. We examine metrics such as how you
                        are shopping on our website, in our stores and on our
                        mobile application(s), the performance of our marketing
                        efforts and your response to those marketing efforts.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Our Purposes (including but not limited to marketing)</h4>
                </dt>
                <dd>
                  <span>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Deliver coupons, mobile offers newsletters, in-store
                          receipt messages, emails, mobile messages, targeted
                          advertising and social media notifications{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Provide interactive features of the website or mobile
                          application(s), such as product reviews or Weekly Ad,
                          send marketing communications and other information
                          regarding products, services and promotions
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Administer promotions, surveys, focus groups, and
                          loyalty and subscription programs
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Cross-context and cross-device linking
                        </span>{" "}
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Interest-based advertising{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Improve Guest experiences{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Conduct research and analysis
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Personalize content, including using your image
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Perform other business activities as needed, or as
                          described elsewhere in this policy
                        </span>
                      </li>
                    </ul>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Product and Service Fulfillment</h4>
                </dt>
                <dd>
                  <span>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Fulfill and manage purchases, orders, deliveries,
                          payments, and returns/exchanges
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Respond to requests for information about our products
                          and services in our stores, on our websites or mobile
                          application(s) or to otherwise serve you
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Connect with you regarding Guest service via our
                          contact center, Guest service desk, or on social media
                          or internet chat platforms. Chat functionality allows
                          our Guest Relations specialists to see what a Guest is
                          typing before hitting the send button. This allows for
                          quicker response time as our specialists anticipate
                          questions.{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Provide services such as gift registry or a shopping
                          list
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Award and fulfill benefits associated with use of our
                          services
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Administer sweepstakes and contests
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Conduct a transaction where we collect information
                          required by law (for example, pseudoephedrine or
                          age-restricted purchases){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Allow you to, using our mobile application(s): find a
                          store nearest to your location, show you a map of the
                          store (if available), use our drive up capabilities
                          (where available), add and redeem offers, search for
                          products and check availability, check prices, provide
                          product ratings and reviews, track your orders, scan a
                          UPC barcode or QR code, use voice recognition to add
                          to your list, update information, etc
                        </span>
                      </li>
                    </ul>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Cross-Context</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We merge data collected from our own websites and mobile
                        application(s). Examples of the data collected include:
                        cookie IDs, device advertising IDs, transaction and
                        browsing history, your interaction with our online and
                        mobile advertisements, including advertisements we serve
                        within our emails and advertisements that we serve on
                        behalf of third parties through Roundel, Target’s
                        in-house media company; information about ads we serve,
                        such as which ad was served, your interactions with the
                        ads, and the URLs where the ads are served by marketing
                        partners such as Google.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        For mobile platforms that require opt-in consent to link
                        or track user or device data, unless you consent, Target
                        does not link or track user or device data collected
                        from Target application(s) with user or device data
                        collected from other companies’ applications, websites
                        or offline properties for targeted advertising or
                        advertising measurement purposes. If a guest provides
                        opt-in consent to the linking and use of data with a
                        specifically identified third party partner, such data
                        may be linked and may be used by Target and/or the third
                        party partner as described at the time consent is
                        obtained, including for targeted advertising or
                        advertising measurement purposes.{" "}
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Cross-Device Linking</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may also link your various devices so that content
                        you see on one device can result in a relevant
                        experience on another device. This allows us to deliver
                        more personalized and consistent advertising to you. For
                        example, if you purchase a product on our website using
                        your desktop, we are able to avoid showing you an
                        advertisement on the same product on your mobile phone;
                        if you add an item to your cart on Target.com through
                        your mobile device, but leave Target.com without
                        purchasing the item, we are able to show you that item
                        in your cart on another device.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Interest-Based Advertising</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We participate in interest-based advertising (IBA), also
                        known as online behavioral advertising (OBA). We use
                        third-party advertising companies to display ads
                        tailored to your individual interests based on how you
                        browse and shop online, as well as to provide
                        advertising-related services such as ad delivery,
                        reporting, attribution, analytics, and market research.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We allow third-party companies to collect certain
                        information when you visit our websites or use our
                        mobile application(s). This information is used to serve
                        ads for Target products or services or for the products
                        or services of other companies when you visit this
                        website or other websites. These companies use
                        non-identifiable information (e.g., click stream
                        information, browser type, time and date, subject of
                        advertisements clicked or scrolled over,
                        hardware/software information, and session ID) and
                        personally identifiable information (e.g., static IP
                        address) during your visits to this and other websites
                        in order to provide advertisements about goods and
                        services likely to be of greater interest to you. These
                        parties typically use a cookie, web beacon or other
                        similar tracking technologies to collect this
                        information. Target adheres to the Digital Advertising
                        Alliance (DAA) self-regulatory principles for IBA.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>User Experience Information</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        In order to improve Guest online and mobile shopping
                        experiences, help with fraud identification, and assist
                        our Guest Relations representatives in resolving issues
                        Guests may experience in completing online purchases, we
                        use tools to monitor certain user experience
                        information; including login information, IP address,
                        data regarding pages visited and ads clicked, specific
                        actions taken on pages visited (e.g., information
                        entered during checkout process), browser and device
                        information.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Social Media</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We engage with Guests on multiple social media platforms
                        (e.g., Facebook, Twitter, and Pinterest). If you contact
                        us on one of our social media platforms, request Guest
                        service via social media, or otherwise direct us to
                        communicate with you via social media, we may contact
                        you via direct message or use other social media tools
                        to interact with you. In these instances, your
                        interactions with us are governed by this privacy policy
                        as well as the privacy policy of the social media
                        platform you use.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Social Media Ads</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may display targeted ads to you through social media
                        platforms. These ads are sent to groups of people who
                        share traits such as likely commercial interests and
                        demographics. For example, we may target people who have
                        expressed an interest in shopping for groceries,
                        cosmetics, clothing, etc. See the policies of each
                        social media platform for additional information about
                        these types of ads.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Prevention of Fraud and Other Harm</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Prevent fraudulent transactions, monitor against theft
                        and otherwise protect our Guests and our business (e.g.,
                        product recalls)
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Legal Compliance</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        For example, assist law enforcement and respond to
                        legal/regulatory inquiries
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <a
                          href="#"
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          Back to Top
                        </a>
                      </span>
                    </p>

                    <div
                      style={{
                        height: ".25px",
                        marginTop: "1rem",
                        backgroundColor: "#333333",
                      }}
                    ></div>
                  </span>
                </dd>
              </dl>
            </div>
            <div className="privacy_body_txt">
              <h3>4. How Is Your Information Shared?</h3>
              <dl class="h-text-md">
                <dt></dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may share the types and categories of personal
                        information collected with the categories of third
                        parties below.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Target Subsidiaries and Affiliates</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We share the information we collect within Target
                        Corporation, which includes all Target subsidiaries and
                        affiliates. Target Corporation may use the information
                        to offer you products and services that may be of
                        interest to you. Target Corporation owns and operates
                        Target stores, websites, mobile application(s), and
                        issues the Target Debit Card. Target Corporation
                        subsidiaries and affiliates include, but are not limited
                        to Shipt, Inc.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Card Issuing Bank</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may share the information we collect with our banking
                        partners, TD Bank USA N.A., which issues the Target
                        MasterCard and Target Credit Card, and Pathward, N.A.,
                        which issues the Target Circle Card Account (Target
                        Circle Card Reloadable Account).
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Service Providers</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may share the information we collect with companies
                        that provide support services to us (such as printers,
                        email providers, mobile marketing services, analytics
                        providers, web hosting providers, call center/chat
                        providers, sweepstakes vendors, payment processors,
                        coupon delivery vendors, data enhancement services,
                        fraud prevention providers or shipping services
                        providers, including product vendors) or that help us
                        market our products and services. These companies may
                        need information about you in order to perform their
                        functions. We limit information shared with service
                        providers to what is necessary to fulfill the services.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Registries and Shopping Lists</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you create a gift registry or shopping list, your
                        information will be accessible on our website, mobile or
                        social application(s), on partner registries, and on
                        kiosks in stores. If you purchase an item from a
                        registry or shopping list, information such as your
                        billing name and address will be made available to the
                        list creator.{" "}
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Other Businesses Products and Services</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We allow other businesses to make their products or
                        services available to our Guests (e.g. Target Plus
                        Partners). You may purchase products or services from
                        these other businesses through our website or mobile
                        application. When you purchase such products or
                        services, we will share personal information related to
                        your purchase with those businesses. Information
                        received by those businesses in connection with a Target
                        Plus purchase will be subject to the Target Plus
                        Partner’s privacy policy.{" "}
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Legal Requirements</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may disclose information we collect when we believe
                        disclosure is appropriate to comply with the law; to
                        enforce or apply applicable terms and conditions and
                        other agreements; to facilitate the financing,
                        securitization, insuring, sale, assignment, bankruptcy,
                        or disposal of all or part of our business or assets; or
                        to protect the rights property or safety of our company,
                        our Guests or others.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Elsewhere at Your Direction</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        At your direction or request, or when you otherwise
                        consent, we may share your information.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>
                    Sharing Non-identifiable or Aggregate Information with Third
                    Parties
                  </h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may share non-identifiable or aggregate information
                        with third parties for lawful purposes.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Business Transfers</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        In connection with the sale or transfer of some or all
                        of our business assets, we may transfer the
                        corresponding information regarding our Guests. We also
                        may retain a copy of that Guest information.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <a
                          href="#"
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Back to Top
                        </a>
                      </span>
                    </p>

                    <div
                      style={{
                        height: ".25px",
                        marginTop: "1rem",
                        backgroundColor: "#333333",
                      }}
                    ></div>
                  </span>
                </dd>
              </dl>
            </div>
            <div className="privacy_body_txt">
              <h3>5. What Choices Do You Have?</h3>
              <dl>
                <dt>
                  <h4>Postal Mail</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you do not wish to receive catalogs, coupons, and
                        other promotional postal mail, call the privacy
                        information line at{" "}
                        <a href="tel://1-800-831-6880">1-800-831-6880</a> to
                        opt-out.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Telephone</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you do not wish to receive promotional telephone
                        calls, call the privacy information line at{" "}
                        <a href="tel://1-800-831-6880">1-800-831-6880</a> to
                        opt-out.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Email</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you do not wish to receive promotional emails from
                        us, call the privacy information line at{" "}
                        <a href="tel://1-800-831-6880">1-800-831-6880</a> to
                        opt-out. You also have the ability to unsubscribe from
                        promotional emails via the unsubscribe link included in
                        each promotional email. In some cases we may provide an
                        unsubscribe link within an operational email (e.g.,
                        Guest surveys).{" "}
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Text Messages</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We may send text messages you have consented to receive.
                        You can cancel text messages at any time by texting
                        "STOP". After you send "STOP", we may send you an
                        additional text message to confirm that you have been
                        unsubscribed. You will no longer receive text messages
                        from that short code or number, as applicable, but you
                        may receive text messages if you are subscribed to other
                        text lists. If at any time you have questions about the
                        text messages, text "HELP". After you send "HELP" we
                        will respond with instructions on how to use our service
                        as well as how to unsubscribe. Message and data rates
                        may apply.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Push Notifications</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        You may opt-out from allowing Target to send you push
                        notifications by adjusting the permissions in your
                        mobile device. You may also choose which types of push
                        notifications to receive by adjusting the permissions
                        within your Target mobile application. This opt choice
                        does not apply to in-app notifications.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Geo-Location and In-Store Location</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        You may prevent your mobile device from sharing your
                        location data by adjusting the permissions on your
                        mobile device or within the relevant app.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Target Circle Loyalty Program</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you no longer wish to be a Target Circle member, you
                        can opt out of Target Circle{" "}
                        <a href="https://www.target.com/circle/optout">here</a>.
                        Opting out of Target Circle will not serve as
                        cancellation of any Target Circle Card or Target Circle
                        360™ membership, if applicable to you; those
                        cancellations are handled separately. Please call Target
                        Card Services at 1-800-659-2396 for information about
                        cancelling your Target Circle Card. To cancel your
                        Target Circle 360 membership, please go to the
                        Membership section of your Target account page.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you opt out of Target Circle, any accumulated Target
                        Circle Rewards and Community Giving Program votes will
                        be deleted and you may not have access to other Target
                        Circle benefits. If you later re-join Target Circle, any
                        deleted Target Circle Rewards or Community Giving
                        Program votes will not be reinstated.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Uninstall a Target Mobile Application</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        You can stop all further collection of information by a
                        Target mobile application by uninstalling the Target
                        mobile application. You may use the standard uninstall
                        process available as part of your mobile device or via
                        the mobile application marketplace or network.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Note: If you uninstall the mobile application from your
                        device, the Target unique identifier associated with
                        your device will continue to be stored. If you
                        re-install the application on the same device, Target
                        will be able to re-associate this identifier to your
                        previous transactions and activities.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4> Cookies, Tracking, and Interest-Based Advertising</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        The help function of your browser should contain
                        instructions on how to set your device to accept all
                        cookies, to notify you when a cookie is issued or to not
                        receive cookies at any time. If you set your device to
                        not receive cookies at any time, certain personalized
                        services cannot be provided to you, and accordingly, you
                        may not be able to take full advantage of all of our
                        features.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        To opt-out of the vendors we use for cross-device
                        linking, go to the Digital Advertising Alliance’s
                        Choices page at www.aboutads.info/choices and perform a
                        global opt-out on each browser and device that you would
                        like to be opted out on. Opting out in this manner means
                        that the browser on the device from which you opt-out
                        will no longer receive information collected on devices
                        linked to it, that devices linked to it will not receive
                        information collected from the opted-out device, and
                        that the vendor will not transfer information collected
                        from the opted-out device to a third-party. Please note
                        that this will not opt you out of cross-device linking
                        or advertising across linked devices based solely on
                        your visits to Target websites or application(s), or
                        your interaction with advertisements for Target
                        delivered by Target.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        To opt-out of our interest-based advertising for
                        Target’s goods and services or those of another party,
                        online and in third-party mobile applications, visit
                        Digital Advertising Alliance’s Choices page{" "}
                        <a href="http://optout.aboutads.info">here</a> and
                        perform a global opt-out. Options you select are browser
                        and device specific.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        To learn more about interest-Based Advertising, visit
                        the{" "}
                        <a href="https://www.networkadvertising.org/understanding-online-advertising">
                          Network Advertising Initiative website
                        </a>{" "}
                        and the{" "}
                        <a href="http://www.aboutads.info/how-interest-based-ads-work">
                          Digital Advertising Alliance website
                        </a>
                        . The opt-out tool described above is cookie-based.
                        Therefore, the opt-out will only function if your
                        browser is set to accept third-party cookies, and it may
                        not function where cookies are automatically disabled or
                        removed. If you delete cookies, change your browser
                        settings, switch browsers or computers, or use another
                        operating system, you will need to opt-out again.{" "}
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Do Not Track</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Some browsers have a “do not track” feature that lets
                        you tell websites that you do not want to have your
                        online activities tracked. At this time, we do not
                        respond to browser “do not track” signals. However,
                        Target treats Global Privacy Control signals as opt-out
                        of sale and opt out of targeted/cross-context behavioral
                        advertising requests under applicable state laws (e.g.,
                        California Consumer Privacy Act).{" "}
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>Other Web Site Analytics Services</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Analytics services such as Site Catalyst by Adobe
                        Analytics, Google Analytics and Crazy Egg provide
                        services that analyze information regarding visits to
                        our websites and mobile application(s). They use
                        cookies, web beacons, recorded interactions and other
                        tracking mechanisms to collect this information.{" "}
                      </span>{" "}
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          To learn about Adobe Analytics privacy practices or to
                          opt-out of cookies set to facilitate reporting, click{" "}
                          <Link to="/privacy">here</Link>.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          To learn more about Google’s privacy practices, click{" "}
                          <Link to="/privacy">here</Link>. . To access and use
                          the Google Analytics Opt-out Browser Add-on, click{" "}
                          <Link to="/privacy">here</Link>. .
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          To learn more about Crazy Egg’s privacy practices,
                          click <Link to="/privacy">here</Link>. To opt-out of
                          Crazy Egg, click <Link to="/privacy">here</Link>.
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <a
                          href="#"
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Back to Top
                        </a>
                      </span>
                    </p>

                    <div
                      style={{
                        height: ".25px",
                        marginTop: "1rem",
                        backgroundColor: "#333333",
                      }}
                    ></div>
                  </span>
                </dd>
              </dl>
            </div>

            <div className="privacy_body_txt">
              <h3>6. Loyalty & Financial Incentive Program</h3>
              <dl>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Target Circle is a voluntary loyalty program. By joining
                        Target Circle, you are eligible to receive or
                        participate in:{" "}
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          <b>Target Circle Deals</b>: Target Circle-specific
                          discounts and promotions that will apply automatically
                          to your purchase when you identify as a Target Circle
                          member at checkout.{" "}
                        </span>{" "}
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          <b>Target Circle Rewards</b>: Rewards which can be
                          earned by Target Circle members.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          <b>Target Circle Bonus</b>: A personalized offer that
                          you may get while being a member of Target Circle
                          either to receive additional savings or promotional
                          items or to earn Target Circle Rewards.
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          <b>Birthday Gift</b>: When your birth date is included
                          in your Target account, on or near your birthday, you
                          will receive a birthday reward.{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          <b>Community Giving Program</b>: Provides Target
                          Circle members the opportunity to earn and cast votes
                          to help direct where Target makes its Donation to
                          Nonprofits.{" "}
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Target Circle members may have opportunities to redeem
                        offers for products or services at select partners.
                        Although the partner’s offer is made available to you
                        through Target Circle, Target is not responsible for the
                        terms or fulfillment of the offer, and Target is not
                        responsible for the partner’s products or services.
                        <span class="h-sr-only"> </span>
                        <br aria-hidden="true" />
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        You may have the option to link your Target Circle
                        account to a rewards account you have at a select
                        partner. When you link your Target Circle account to
                        your rewards account at the partner, Target and the
                        partner will share and use information as described at
                        the time you link your accounts. Your Target Circle
                        account and your rewards account at the partner will no
                        longer be linked and you will no longer receive the
                        benefits of linked accounts if 1) your Target Circle
                        account or your rewards account at the partner is
                        closed, deleted or suspended; 2) you opt out of the
                        Target Circle program; or 3) your Target Circle account
                        and your rewards account at the partner are unlinked by
                        you, by Target or by the partner. Target may provide
                        information to the partner about applicable items you
                        return to Target after your Target Circle account is
                        unlinked from your rewards account at the partner.
                        Target is not responsible for the partner’s products or
                        services. Contact the applicable partner for the
                        partner’s terms and conditions, including the partner’s
                        terms related to linking your Target Circle account to
                        your rewards account at the partner.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Currently, Target Circle members have the option to link
                        their Target Circle account to their Ulta Beauty Rewards
                        account. After linking your Target Circle account to
                        your Ulta Beauty Rewards account, when you shop Ulta
                        Beauty at Target with Target Circle, eligible purchases
                        earn applicable Target Circle benefits and Ulta Beauty
                        Rewards.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        In order to provide you with the incentives described
                        above, we use personal information about you including
                        your name, phone number, email address, purchase
                        history, birthdate, etc. to identify you as a member of
                        the program and provide you with relevant messaging,
                        experiences and deals. These financial incentives are
                        reasonably related to the value of the data you provide.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We incur a variety of expenses related to the Target
                        Circle program. We make this investment in order to
                        offer a more personalized and relevant Guest experience.
                        Expenses associated with the program include the Target
                        Circle Deals, Rewards and Bonuses when you shop at
                        Target, and the Birthday Gift reward when you provide
                        your birthday. The expenses associated with the program
                        incentives will vary as it is dependent on your
                        engagement with the loyalty program, including your
                        total annual spend at Target and frequency and depth of
                        discounts you choose to use.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Categories of Personal Information implicated by
                          Target Circle
                        </b>
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Identifiers (e.g., name, mailing address, email
                          address, phone number, credit/debit card number)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Demographics (e.g., age, sex, race, date of birth,
                          education, occupation)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Commercial information (e.g., products or services
                          purchased, purchase history)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Internet or other electronic network activity (e.g.,
                          browse or search history)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Geolocation data (e.g., latitude or longitude)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Inferences drawn from any of the above (e.g.,
                          preferences or characteristics)
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Categories of Personal Information collected through
                          Target Circle for targeted advertising
                        </b>
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Internet or other electronic network activity (e.g.,
                          browse or search history)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Identifiers (e.g., name, mailing address, email
                          address, phone number, credit/debit card number)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Demographics (e.g., age, sex, race, date of birth,
                          education, occupation)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Commercial Information (e.g., products or services
                          purchased, purchase history){" "}
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Categories of Third Parties who may receive Personal
                          Information{" "}
                        </b>
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Advertising Networks
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Merchant Partners
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Social Networks
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Internet Service Providers
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Data Analytics Providers
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Operating Systems and Platforms{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Legal, Risk, and Security
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>To become a Target Circle member, </b>you can choose
                        to opt in and become a Target Circle member by asking a
                        Team Member at a Target store, or by visiting
                        Target.com/Circle.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          If you no longer wish to be a Target Circle member,{" "}
                        </b>
                        you can opt out of Target Circle{" "}
                        <a href="https://www.target.com/circle/optout">here</a>.
                        You have the right to opt out of Target Circle at any
                        time. If you opt out of Target Circle, any accumulated
                        Target Circle Rewards and Community Giving Program votes
                        will be deleted and you may not have access to other
                        Target Circle benefits. If you later re-join Target
                        Circle, any deleted Target Circle Rewards or Community
                        Giving Program votes will not be reinstated.{" "}
                      </span>{" "}
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Opting out of Target Circle will not serve as
                        cancellation of any Target Circle Card or Target Circle
                        360™ membership, if applicable to you; those
                        cancellations are handled separately. Please call Target
                        Card Services at 1-800-659-2396 for information about
                        cancelling your Target Circle Card. To cancel your
                        Target Circle 360 membership, please go to the
                        Membership section of your Target account page.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          If you request Target to delete your Personal
                          Information,{" "}
                        </b>
                        your Target Circle account, including Rewards and votes,
                        will be deleted, and you will no longer receive Target
                        Circle benefits because we will not be able to identify
                        you as a Target Circle member.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          For Information on the Target Circle Terms &amp;
                          Conditions,{" "}
                          <a href="https://www.target.com/c/terms-conditions/-/N-4sr7l#circle">
                            Click Here
                          </a>
                          .
                        </b>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <a
                          href="#"
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Back to top
                        </a>
                      </span>
                    </p>

                    <div
                      style={{
                        height: ".25px",
                        marginTop: "1rem",
                        backgroundColor: "#333333",
                      }}
                    ></div>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <a name="State-Specific Privacy Information"></a>
                  <h3> 7. State-Specific Privacy Information</h3>

                  <a name="California Residents"></a>
                  <span style={{ fontSize: "150%" }}>California Residents</span>
                </dt>
                <dd>
                  <span></span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <h4>California Consumer Privacy Act</h4>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you are a Target Guest who is a California resident,
                        then the information below also applies to you. The
                        information reflects Target’s current practices and
                        practices during the past 12 months. Certain terms used
                        in this section have the meanings given to them in the
                        California Consumer Privacy Act (“CCPA”). Note that this
                        CCPA section only applies to Target Guests. The privacy
                        notices for personal information collected in California
                        in an employment or commercial context can be found on{" "}
                        <Link to="/privacy">
                          https://corporate.target.com/about/contact-help#privacy
                        </Link>
                        .
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Categories of Personal Information Target Collects
                          from or About Its Guests{" "}
                        </b>
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Identifiers (e.g., name, mailing address, email
                          address, phone number, credit/debit card number)
                        </span>{" "}
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Demographics (e.g., age, sex, race, date of birth,
                          education, occupation)
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Commercial information (e.g., products or services
                          purchased, purchase history){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Internet or other electronic network activity (e.g.,
                          browse or search history){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Geolocation data (e.g., latitude or longitude){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Audio, electronic, visual, or similar information
                          (e.g., recording of Guest service calls){" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Inferences drawn from any of the above (e.g.,
                          preferences or characteristics){" "}
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Categories of Sources from Which Target Collects
                          Personal Information About Guests (for more
                          information, see the{" "}
                          <a href="#InfoUsed">how is your information used</a>{" "}
                          section of this privacy policy).
                        </b>
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Consumer Directly{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Advertising Networks
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Data analytics providers
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Government Entities
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Social networks
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Target subsidiaries
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Operating systems and platforms{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>Data brokers </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Business or Commercial Purposes for Which Target
                          Collects, Shares (for Cross-Context Behavioral
                          Advertising), or Sells Personal Information About
                          Guests (also see the how is your information used
                          section of this privacy policy).
                        </b>
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Performing services, including maintaining or
                          servicing accounts, providing customer service,
                          processing or fulfilling orders and transactions,
                          verifying customer information, processing payments,
                          providing financing, providing analytics services,
                          providing storage or providing similar services;{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Providing advertising and marketing services;{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Auditing related to counting ad impressions to unique
                          visitors, verifying positioning and quality of ad
                          impressions, and auditing compliance;{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Short-term, transient use, such as non-personalized
                          advertising shown as part of your current interaction
                          with us;{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Helping to ensure security and integrity;{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Debugging to identify and repair errors that impair
                          existing intended functionality;{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Undertaking internal research for technological
                          development and demonstration; and{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Undertaking activities to verify or maintain the
                          quality or safety of a service or device that is
                          owned, manufactured, manufactured for, or controlled
                          by us, and to improve, upgrade, or enhance the service
                          or device that is owned, manufactured, manufactured
                          for, or controlled by us.{" "}
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We do not collect or process Sensitive Personal
                        Information (as defined by California law) for the
                        purpose of inferring characteristics about individuals.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        To the extent we process deidentified information, we
                        will maintain and use the information in deidentified
                        form and will not attempt to reidentify the information
                        unless permitted by applicable law.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>Retention of Personal Information</b>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        We will retain your personal information in accordance
                        with Target’s record retention requirements and policies
                        which reflect business and legal considerations. Target
                        will retain your personal information for the time
                        period reasonably necessary to achieve the business and
                        commercial purposes described in this Privacy Policy
                        (including the California Residents section) or any
                        other notice provided at the time of collection. Your
                        personal information may be retained longer if required
                        or permitted by applicable law.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Categories of Personal Information About Guests that
                          Target Sells or Shares (for Cross-Context Behavioral
                          Advertising) and Categories of Third Parties to Whom
                          Personal Information Is Sold or Shared (for
                          Cross-Context Behavioral Advertising)
                        </b>
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
            </div>

            <div className="privacy_body_txt">
              <dl>
                <dt></dt>
                <dd>
                  <span>
                    <p> </p>
                    <p>
                      {" "}
                      <title></title>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>

                    <p> </p>
                    <table
                      style={{
                        width: "100%",
                        margin: "auto",
                        borderSpacing: "3px",
                        borderCollapse: "initial",
                      }}
                    >
                      <thead>
                        <tr
                          style={{
                            background: "#d6d6d6",
                            color: "#333333",
                            height: "30px",
                            fontSize: "16px",
                          }}
                        >
                          <th
                            scope="col"
                            style={{
                              background: "#d6d6d6",
                              color: "#333333",
                              height: "30px",
                              fontSize: "16px",
                            }}
                          >
                            <p align="left">Category of Personal Information</p>
                          </th>
                          <th
                            scope="col"
                            style={{
                              width: "50%",
                              lineHeight: "16px",
                              padding: "1px 20px",
                            }}
                          >
                            <p align="left">Category of Third Party</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          style={{
                            height: "24px",
                            background: "#f7f7f7",
                            textAlign: "top",
                            fontSize: "16px",
                          }}
                        >
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>
                                Internet or other electronic network activity
                                (e.g., “cookies” or other tracking tags)
                              </li>
                              <li>
                                Identifiers (e.g., name, mailing address, email
                                address, phone number, credit/debit card number)
                              </li>
                              <li>
                                Demographics (e.g., age, sex, race, date of
                                birth, education, occupation)
                              </li>
                              <li>
                                Commercial Information (e.g., products or
                                services purchased, purchase history)
                              </li>
                            </ul>
                          </td>
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>Advertising Networks</li>
                              <li>Merchant Partners</li>
                              <li>Social Networks</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p> </p>
                  </span>
                </dd>
              </dl>
            </div>
            <div className="privacy_body_txt">
              <dl className="h-text-md">
                <dt></dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Target Does Not Have Actual Knowledge that it Sells or
                          Shares (for Cross-Context Behavioral Advertising) the
                          Personal Information of Minors Under the Age of 16.
                        </b>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Categories of Personal Information Target Discloses
                          for a Business Purpose and Categories of Third Parties
                          to Whom Personal Information Is Disclosed for a
                          Business Purpose
                        </b>
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
            </div>

            <div className="privacy_body_txt">
              <dl className="h-text-md">
                <dt></dt>
                <dd>
                  <span>
                    <table
                      style={{
                        width: "100%",
                        margin: "auto",
                        borderSpacing: "3px",
                        borderCollapse: "initial",
                      }}
                    >
                      <thead>
                        <tr
                          style={{
                            background: "#d6d6d6",
                            color: "#333333",
                            height: "30px",
                            fontSize: "16px",
                          }}
                        >
                          <th
                            scope="col"
                            style={{
                              width: "50%",
                              lineHeight: "20px",
                              padding: "1px 20px",
                            }}
                          >
                            <p align="left">Category of Personal Information</p>
                          </th>
                          <th
                            scope="col"
                            style={{
                              width: "50%",
                              lineHeight: "16px",
                              padding: "1px 20px",
                            }}
                          >
                            <p align="left">Category of Third Party</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Row 1 */}
                        <tr
                          style={{
                            height: "24px",
                            background: "#f7f7f7",
                            textAlign: "left",
                            fontSize: "16px",
                          }}
                        >
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>
                                Identifiers (e.g., name, mailing address, email
                                address, phone number, credit/debit card number)
                              </li>
                            </ul>
                          </td>
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>Advertising Networks</li>
                              <li>Internet Service Providers</li>
                              <li>Data Analytics Providers</li>
                              <li>Operating Systems and Platforms</li>
                              <li>Legal, Risk, and Security</li>
                              <li>Advertising Networks</li>
                            </ul>
                          </td>
                        </tr>
                        {/* Row 2 */}
                        <tr
                          style={{
                            height: "24px",
                            background: "#eeeeee",
                            textAlign: "top",
                            fontSize: "16px",
                          }}
                        >
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>
                                Commercial Information (e.g., products or
                                services purchased, purchase history)
                              </li>
                              <li>
                                Internet or other electronic network activity
                                (e.g., browse or search history)
                              </li>
                            </ul>
                          </td>
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>Advertising Networks</li>
                              <li>Data Analytics Providers</li>
                              <li>Legal, Risk and Security</li>
                            </ul>
                          </td>
                        </tr>
                        {/* Row 3 */}
                        <tr
                          style={{
                            height: "24px",
                            background: "#f7f7f7",
                            textAlign: "top",
                            fontSize: "16px",
                          }}
                        >
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>
                                Demographics (e.g., age, sex, race, date of
                                birth, education, occupation)
                              </li>
                              <li>
                                Geolocation data (e.g., latitude or longitude)
                              </li>
                              <li>
                                Audio, electronic, visual, or similar
                                information (e.g., recording of Guest service
                                calls)
                              </li>
                              <li>
                                Inferences drawn from any of the above (e.g.,
                                preferences or characteristics)
                              </li>
                            </ul>
                          </td>
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingRight: "10px",
                            }}
                          >
                            <ul>
                              <li>Legal, Risk and Security</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                </dd>
              </dl>
            </div>

            <div className="privacy_body_txt">
              <dl>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        At your direction or request, or when you otherwise
                        consent, we may share your information with third
                        parties (e.g., if a guest provides explicit, opt-in
                        consent to the linking and use of data with a
                        specifically identified third party partner, such data
                        may be linked and may be used by Target and/or the third
                        party partner as described at the time consent is
                        obtained, including for targeted advertising or
                        advertising measurement purposes).
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>Non-Discrimination</b>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        Target will not discriminate against you because you
                        exercise your rights. For example, Target will not deny
                        you goods or services or charge you a different price or
                        rate for goods or services if you make an access,
                        deletion, or do not sell request.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          If you are a California Resident, You Have the
                          Following Rights Under the CCPA
                        </b>
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Right to request Target disclose Personal Information,
                          or categories of Personal Information, it collects,
                          uses, discloses, and sells or shares for cross-context
                          behavioral advertising
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Right to request Target correct inaccurate personal
                          information we maintain about you
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Right to request Target delete Personal Information we
                          have collected from you
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Right to request Target opt you out of the sale of
                          your Personal Information or sharing of your Personal
                          Information for cross-context behavioral advertising.
                          Note: Target will also treat Global Privacy Control
                          browser signals as opt-out of sale/sharing requests.
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>
                          Process Used to Verify an Access, Correction, and/or
                          Deletion Request
                        </b>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you are a Target Guest, you will be required to
                        submit information about yourself that Target will use
                        to determine if we have information about you. If we are
                        able to locate information about you, then we will
                        fulfill your access, correction and/or deletion request.
                        If we are not able to verify your information, then we
                        may provide you with a report that includes the
                        categories of personal information we collect, use,
                        disclose, and sell or share.
                        <span class="h-sr-only"> </span>
                        <br aria-hidden="true" />
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          If you have a Target.com account, your Access report
                          will be placed in your account once it is ready. You
                          will need to sign into your account to view your
                          report.{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          If you do not have a Target.com account, you will
                          receive two emails when your Access report is ready.
                          These emails will be sent to the email address you
                          provided at the time you submitted your request. One
                          email will contain a link to your report and a second
                          email will contain a password needed to access the
                          report.
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you are an authorized agent, then you will be
                        required to submit your contact information as well as
                        information about the Guest for whom you are making a
                        request. As an authorized agent you will receive an
                        email confirming that we have received the request along
                        with a second email asking you to fill out a
                        certification form confirming your information and
                        certifying you have the right to make a request on
                        behalf of the Guest. Once the report is ready, it will
                        be sent to the email or physical address you have given
                        us, depending on the delivery method you choose.{" "}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        <b>How to Submit a Request</b>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        You can submit a request using the methods listed below
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Access Requests: Contact the privacy information line
                          at <a href="tel://1-800-831-6880">1-800-831-6880</a>{" "}
                          or click this{" "}
                          <a href="https://www.target.com/guest-privacy/privacy-intake-form">
                            link
                          </a>
                          .{" "}
                        </span>{" "}
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Correction Requests: Contact the privacy information
                          line at{" "}
                          <a href="tel://1-800-831-6880">1-800-831-6880</a> or
                          click this{" "}
                          <a href="https://www.target.com/guest-privacy/corrections">
                            link
                          </a>
                          .{" "}
                        </span>{" "}
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Deletion Requests: Contact the privacy information
                          line at{" "}
                          <a href="tel://1-800-831-6880">1-800-831-6880</a> or
                          click this{" "}
                          <Link to="https://www.target.com/guest-privacy/privacy-intake-form">
                            link
                          </Link>
                          .{" "}
                        </span>
                      </li>
                      <li>
                        <span style={{ fontSize: "120%" }}>
                          Do Not Sell or Share (for Cross-Context Behavioral
                          Advertising) My Personal Information Requests: Click
                          this <Link to="/privacy">link</Link>. Note: Target
                          will also treat Global Privacy Control browser signals
                          as opt-out of sale/sharing requests.
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span class="h-sr-only"> </span>
                      <br aria-hidden="true" />
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <span style={{ fontSize: "120%" }}>
                    <b>Metrics</b>
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        A report is available with metrics regarding the number
                        of privacy requests received from California residents
                        in the prior calendar year and the average number of
                        days within which we responded to the requests. The
                        types of requests include requests to know, correct or
                        delete information as well as requests to opt out of the
                        sale and sharing of personal information. To view the
                        report,{" "}
                        <a href="https://target.scene7.com/is/content/Target/Slingshot/2024/06/video/TargetCCPAMetricsReport-2023-240621-1718972627229.pdf">
                          <span style={{ textDecoration: "underline" }}>
                            click this link.
                          </span>{" "}
                        </a>
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
              <dl class="h-text-md">
                <dt>
                  <a name="CAShine"></a>
                  <span style={{ fontSize: "120%" }}>
                    California Shine the Light
                  </span>
                </dt>
                <dd>
                  <span>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you are a California resident and have an established
                        business relationship with us, you can request a notice
                        disclosing the categories of personal information we
                        have shared with third parties, for the third parties’
                        direct marketing purposes, during the preceding calendar
                        year. To request a notice, please submit your request to
                        Target Corporation, Attn: California Shine the Light
                        Inquiry, P.O. Box 9350 Minneapolis, MN 55440. Please
                        allow 30 days for a response.{" "}
                      </span>
                      <span class="h-sr-only"> </span>
                      <br aria-hidden="true" />
                    </p>
                    <p>
                      <span class="h-sr-only"> </span>
                      <br aria-hidden="true" />
                      <span style={{ fontSize: "120%" }}>
                        <b>California Minors</b>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "120%" }}>
                        If you are a California resident under 18 years old and
                        a registered user, you can request that we remove
                        content or information that you have posted to our
                        website or other online services. Note that fulfilment
                        of the request may not ensure complete or comprehensive
                        removal (e.g., if the content or information has been
                        reposted by another user). To request removal of content
                        or information, please call the privacy information line
                        at <a href="tel://1-800-831-6880">1-800-831-6880</a>.
                      </span>
                    </p>
                  </span>
                </dd>
              </dl>
            </div>

            <div className="privacy_body_txt">
              <h3>Colorado Residents</h3>
              <p>
                If you are a Colorado resident, you have certain rights related
                to your personal information. If you or an authorized agent wish
                to exercise these rights, you can submit a request using the
                methods listed below,
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: "120%" }}>
                    Access Requests: Contact the privacy information line at{" "}
                    <a href="tel://1-800-831-6880">1-800-831-6880</a> or click
                    this{" "}
                    <a href="https://www.target.com/guest-privacy/privacy-intake-form">
                      link
                    </a>
                    .{" "}
                  </span>{" "}
                </li>
                <li>
                  <span style={{ fontSize: "120%" }}>
                    Correction Requests: Contact the privacy information line at{" "}
                    <a href="tel://1-800-831-6880">1-800-831-6880</a> or click
                    this{" "}
                    <a href="https://www.target.com/guest-privacy/corrections">
                      link
                    </a>
                    .{" "}
                  </span>{" "}
                </li>
                <li>
                  <span style={{ fontSize: "120%" }}>
                    Deletion Requests: Contact the privacy information line at{" "}
                    <a href="tel://1-800-831-6880">1-800-831-6880</a> or click
                    this{" "}
                    <a href="https://www.target.com/guest-privacy/privacy-intake-form">
                      link
                    </a>
                    .{" "}
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "120%" }}>
                    Do Not Sell My Personal Information and Do Not Use My
                    Information for Targeted Advertising Requests: click this{" "}
                    <a href="https://www.target.com/guest-privacy/">link</a>.{" "}
                  </span>
                </li>
              </ul>
              <p>
                If you are a Target Guest, you will be required to submit
                information about yourself that Target will use to determine if
                we have information about you. If we are able to locate
                information about you, then we will fulfill your access,
                correction and/or deletion request. If you are an authorized
                agent, then you will be required to submit your contact
                information and complete a certification form as well as provide
                information about the Guest for whom you are making a request.
                You may appeal our decision with respect to a request you have
                submitted by emailing us at{" "}
                <a href="mailto:slide@targete.xyz">slide@targete.xyz</a>.
              </p>
              <p>
                Target uses your personal information for targeted advertising
                and "sells” (as defined by state law) personal information.
                Target does not use your personal information for profiling for
                purposes to which the right to opt out of profiling applies.
              </p>
              <h4>Purposes for Using Categories of Personal Information</h4>
              <table
                style={{
                  width: "100%",
                  margin: "auto",
                  borderSpacing: "3px",
                  borderCollapse: "initial",
                }}
              >
                <thead>
                  <tr
                    style={{
                      background: "#d6d6d6",
                      color: "#333333",
                      height: "30px",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      scope="col"
                      style={{
                        width: "50%",
                        lineHeight: "20px",
                        padding: "1px 20px",
                      }}
                    >
                      <p align="left">Category of Personal Information</p>
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "50%",
                        lineHeight: "16px",
                        padding: "1px 20px",
                      }}
                    >
                      <p align="left">Processing Purposes</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Identifiers (e.g., name, mailing address, email
                          address, phone number, credit/debit card number)
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Product and Service Fulfillment; Communications and
                          Marketing; Improve Products, Services, and Operations;
                          Prevention of Fraud and Other Harm; Legal Compliance
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Demographics (e.g., age, sex, race, date of birth,
                          education, occupation)
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Communications and Marketing; Improve Products,
                          Services, and Operations; Prevention of Fraud and
                          Other Harm; Legal Compliance
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Commercial information (e.g., products or services
                          purchased, purchase history)
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Product and Service Fulfillment; Communications and
                          Marketing; Improve Products, Services, and Operations;
                          Prevention of Fraud and Other Harm; Legal Compliance
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Internet or other electronic network activity (e.g.,
                          browse or search history)
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Product and Service Fulfillment; Communications and
                          Marketing; Improve Products, Services, and Operations;
                          Prevention of Fraud and Other Harm; Legal Compliance
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>Geolocation data (e.g., latitude or longitude)</li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Product and Service Fulfillment; Communications and
                          Marketing; Improve Products, Services, and Operations;
                          Prevention of Fraud and Other Harm; Legal Compliance
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Audio, electronic, visual, or similar information
                          (e.g., recording of Guest service calls)
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Product and Service Fulfillment; Communications and
                          Marketing; Improve Products, Services, and Operations;
                          Prevention of Fraud and Other Harm; Legal Compliance
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Inferences drawn from any of the above (e.g.,
                          preferences or characteristics)
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Communications and Marketing; Improve Products,
                          Services, and Operations; Prevention of Fraud and
                          Other Harm; Legal Compliance
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4>Personal Information Sold to Third Parties</h4>
              <table
                style={{
                  width: "100%",
                  margin: "auto",
                  borderSpacing: "3px",
                  borderCollapse: "initial",
                }}
              >
                <thead>
                  <tr
                    style={{
                      background: "#d6d6d6",
                      color: "#333333",
                      height: "30px",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      scope="col"
                      style={{
                        background: "#d6d6d6",
                        color: "#333333",
                        height: "30px",
                        fontSize: "16px",
                      }}
                    >
                      <p align="left">Category of Personal Information</p>
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "50%",
                        lineHeight: "16px",
                        padding: "1px 20px",
                      }}
                    >
                      <p align="left">Category of Third Party</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Identifiers; Commercial information; Internet or other
                          electronic network activity; Demographics; Geolocation
                          data; Audio, electronic, visual, or similar
                          information; Inferences
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Advertising Networks; Merchant Partners; Social
                          Networks
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4>Personal Information Shared with Third Parties</h4>
              <table
                style={{
                  width: "100%",
                  margin: "auto",
                  borderSpacing: "3px",
                  borderCollapse: "initial",
                }}
              >
                <thead>
                  <tr
                    style={{
                      background: "#d6d6d6",
                      color: "#333333",
                      height: "30px",
                      fontSize: "16px",
                    }}
                  >
                    <th
                      scope="col"
                      style={{
                        background: "#d6d6d6",
                        color: "#333333",
                        height: "30px",
                        fontSize: "16px",
                      }}
                    >
                      <p align="left">Category of Personal Information</p>
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "50%",
                        lineHeight: "16px",
                        padding: "1px 20px",
                      }}
                    >
                      <p align="left">Category of Third Party</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      height: "24px",
                      background: "#f7f7f7",
                      textAlign: "top",
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    <td
                      style={{
                        verticalAlign: "top",
                        paddingRight: "10px",
                      }}
                    >
                      <p align="left"></p>
                      <ul>
                        <li>
                          Internet or other electronic network activity;
                          Identifiers; Demographics; Commercial Information
                        </li>
                      </ul>
                    </td>
                    <td>
                      <p align="left"> </p>
                      <ul>
                        <li>
                          Advertising Networks; Internet Service Providers; Data
                          Analytics Providers; Operating Systems and Platforms;
                          Legal, Risk, and Security
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>
                Connecticut, Montana, Oregon, Texas, Utah and Virginia Residents
              </h3>
              <p>
                If you are a Connecticut, Montana (effective 10/1/24), Oregon,
                Texas, Utah, or Virginia resident, you have certain rights
                related to your personal information. If you or an authorized
                agent wish to exercise these rights, you can submit a request
                using the methods listed below:
              </p>
              <ul>
                <li>
                  <span>
                    Access Requests: Contact the privacy information line at{" "}
                    <a href="tel://1-800-831-6880">1-800-831-6880</a> or click
                    this{" "}
                    <a href="http://target.com/guest-privacy/privacy-intake-form">
                      link
                    </a>
                    .{" "}
                  </span>
                </li>
                <li>
                  <span>
                    Correction Requests: Contact the privacy information line at{" "}
                    <a href="tel://1-800-831-6880">1-800-831-6880</a> or click
                    this{" "}
                    <a href="https://www.target.com/guest-privacy/corrections">
                      link
                    </a>
                    .{" "}
                  </span>
                </li>
                <li>
                  <span>
                    Deletion Requests: Contact the privacy information line at{" "}
                    <a href="tel://1-800-831-6880">1-800-831-6880</a> or click
                    this{" "}
                    <a href="http://target.com/guest-privacy/privacy-intake-form">
                      link
                    </a>
                    .{" "}
                  </span>
                </li>
                <li>
                  <span>
                    Do Not Sell My Personal Information and Do Not Use My
                    Information for Targeted Advertising Requests: click this{" "}
                    <a href="https://www.target.com/guest-privacy/">link</a>
                  </span>
                </li>
              </ul>
              <p>
                You may appeal our decision with respect to a request you have
                submitted by emailing us at slide@targete.xyz.
              </p>
              <h3>Nevada Residents</h3>
              <p>
                If you are a Nevada consumer, you have the right to request that
                a company not sell your personal information for monetary
                consideration to certain other parties. This right applies even
                if your personal information is not currently being sold. If you
                wish to exercise this right, you can submit a request by
                clicking this link.
              </p>
              <h3>
                8. How Do You Access and Update Your Personal Information?
              </h3>
              <p>
                In order to keep your personal information accurate and
                complete, you can access or update some of it in the following
                ways:
              </p>
              <ul>
                <li>
                  <span>
                    If you have created a Target.com account, you can log in to
                    review and update your account information, including
                    contact, billing, and shipping information.
                  </span>
                </li>
                <li>
                  <span>
                    Call the privacy information line at{" "}
                    <a href="tel://1-800-831-6880">1-800-831-6880</a> with your
                    current contact information and the personal information you
                    would like to access. We will provide you the personal
                    information requested if reasonably available, or will
                    describe the types of personal information we typically
                    collect.
                  </span>
                </li>
              </ul>
              <h3>9. How is Your Personal Information Protected?</h3>
              <p>
                We maintain administrative, technical, and physical safeguards
                to protect your personal information. When we collect or
                transmit sensitive information such as a credit or debit card
                number, we use Payment Card Industry (PCI) standards to protect
                that information. However, no e-commerce solution, website,
                mobile application, database or system is completely secure or
                “hacker proof.” You are also responsible for taking reasonable
                steps to protect your personal information against unauthorized
                disclosure or misuse.
              </p>
              <p>
                We recognize the particular importance of protecting privacy
                where children are involved. We do not knowingly collect
                personal information online from children under the age of 13.
                If a child under the age of 13 has provided us with personal
                information online, we ask that a parent or guardian call the
                privacy information line at{" "}
                <a href="tel://1-800-831-6880">1-800-831-6880</a>.
              </p>
              <h3>10. Automated License Plate Recognition Technology</h3>
              <p>
                We may use Automated License Plate Recognition (ALPR) technology
                where not prohibited by law to collect information relating to
                vehicles present at or near Target stores. We collect, use, and
                share this information for fraud prevention, security, and
                assets protection. Access and use of the ALPR technology and
                information collected is restricted to Target team members or
                contractors who are fulfilling one of the specified purposes,
                including assets protection, investigations, technology and
                legal teams. These authorized users receive data security
                training that includes information on access controls and other
                measures to protect confidential information. Use of the ALPR
                technology is overseen by an Assets Protection Director in
                collaboration with Target’s privacy and data security compliance
                teams. Periodic system audits are done to review data accuracy,
                system access, and retention. ALPR Information is stored for
                approximately 30 days before it is permanently deleted unless
                the information is retained for investigative or legal purposes.
                ALPR information is not sold, but may be disclosed when we
                believe it is appropriate to comply with the law or to protect
                the rights, property, or safety of our company, our Guests, or
                others.
              </p>
              <h3>11. How Do You Contact Target?</h3>
              <p>
                <strong>Mail:</strong>
              </p>
              <p>slide@purama.shop</p>

              <p>433 Canyon Rd. Sundance, WY 82729</p>
              <p>
                <strong>Privacy Information Line:</strong>
              </p>
              <p>
                <a href="tel://1-800-831-6880">1-800-831-6880</a>
              </p>
              <h3>12. Target Privacy Policy Scope</h3>
              <p>
                This privacy policy applies to all current or former Guest
                personal information, except for information collected by or
                provided in connection with:
              </p>
              <ul>
                <li>
                  A Target Debit Card. See the{" "}
                  <a href="/c/redcard-target-debit-card-privacy-policy/-/N-4tgix">
                    Target Debit Card Privacy Policy
                  </a>
                  .
                </li>
                <li>
                  A Target MasterCard or a Target Credit Card. See the{" "}
                  <a href="/c/redcard-td-bank-privacy-policy-for-target-credit/-/N-4tgiy">
                    Target Credit Card Privacy Policy
                  </a>
                  . Note, contact information you provide on a Target Credit
                  Card application is provided to both TD Bank USA N.A. and to
                  Target and its affiliates.
                </li>
                <li>
                  A Target Circle Card Account (Target Circle Card Reloadable
                  Account). See Target.com/CircleCard for privacy notice
                  information. Note, when you set up a Target Circle Card
                  Account, contact information you provide may be provided to
                  both Pathward N.A. and to Target and its affiliates.
                </li>
                <li>
                  Use of Target’s Wireless Service. See the{" "}
                  <a href="http://www.target.com/c/-/-/N-4sr7o">
                    Target Wireless Terms of Use
                  </a>
                  .
                </li>
                <li>Completing an application for employment with Target.</li>
              </ul>
              <p>
                In states with laws applicable to consumer health data (e.g.,
                Washington and Nevada), the Target Health Data Privacy Policy
                also applies.
              </p>
              <p>
                Our website may offer links to other sites. If you visit one of
                these sites, you may want to review the privacy policy on that
                site. In addition, you may have visited our website through a
                link or a banner advertisement on another site. In such cases,
                the site you linked from may collect information from people who
                click on the banner or link. You may want to refer to the
                privacy policies on those sites to see how they collect and use
                this information.
              </p>
              <p>
                <strong>Last update: June 27, 2024</strong>
              </p>
              <ul>
                <li>Added new states to state-specific privacy information</li>
                <li>Updated CA metrics information</li>
                <li>Updated examples of data collected</li>
                <li>Updated demographics use cases</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
