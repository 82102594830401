const productData = [
  {
    id: 1,
    name: "Women's Minky Fleece Pullover Top and Joggers Pajama Set - Auden™",
    like: "39",
    question: "2",
    prePrice: "25.00",
    price: "17.50",
    sale: "30%",
    size: ["XS", "S", "M", "L", "XL", "XXl", "1X", "2X", "3X", "4X"],
    color: ["Cream", "Cream/Plaid", "Green", "Red"],
    images: [
      "https://target.scene7.com/is/image/Target/GUEST_710d4992-9f81-4133-bfdb-c8f88d6dbfb4?wid=1200&hei=1200&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_b7991ed5-e574-4b0e-8b6c-69585b841674?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_402fdae8-f231-4b42-8eca-083c49acbead?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_44fd211d-d52b-41bb-b359-0bd126fc7010?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_24574f79-a747-44aa-9c0c-c247d0d17a57?wid=800&hei=800&qlt=80&fmt=webp",
    ],
    description: {
      main: [
        "Wind down and get some rest in this Minky Fleece Pullover Top and Joggers Pajama Set from Auden™. This pajama set includes a long-sleeve pullover top and joggers for a complete look. The pants features a drawstring closure elastic waistband for a snug fit, while side pockets provide space for small essentials. They're crafted from soft, stretchy knit fabric to keep you in absolute comfort, and you can wear the pieces together or mix and match with other sleepwear for a variety of comfy bedtime looks.",
        "Auden™: Comfort true to every shape & hue.",
      ],
    },
    specification: {
      Dimensions: "",
      Style: "",
      Weight: "",
      ProtectiveQualities: "",
      TextileMaterial: "",
      Includes: "",
      HangingStyle: "",
      FabricWeightType: "",
      NumbersofPiece: "2",
      Sizing: "Womens",
      Piece1: "Sleep Shirt",
      Piece1Material: "95% Recycled Polyester & 5% Spandex",
      Piece1GarmentLength: "At Waist",
      Piece2: " Pajama Pant",
      Piece2Material: "95% Recycled Polyester and 5% Spandex",
      Piece2GarmentLength: "Ankle",
      Piece2GarmentClosure: "Front Drawstring",
      Fit: " Regular Fit",
      SleeveLength: "Long Sleeve",
      FabricName: "Knit",
      Neckline: "Crew",
      GarmentDetails: "Side Pocket",
      IndustryorGovernmentCertifications: "OEKO-TEX STANDARD 100",
      Piece1garmentclosuretype: "Pullover",
      OEKOTEXInstitution: "TESTEX",
      OEKOTEXCertificationNumber: "SH020 217783",
      CareandCleaning: "Machine Wash, Tumble Dry",
      TCIN: "91877519",
      UPC: "198101929778",
      ItemNumber: "020-06-2616",
      Origin: "Imported",
      FlameResistant: "",
      AdultGarment:
        "Adult garment.If the item details above aren’t accurate or complete, we want to know about it. Report incorrect product info.",
    },
  },
  {
    id: 2,
    name: "Waffle Bath Towel - Casaluna™",
    like: "3061",
    question: "22",
    prePrice: "",
    price: "12.00",
    sale: "",
    size: [],
    color: ["Brown", "Gray", "Pink", "True White", "Dark Gray", "Teal Blue"],
    images: [
      "https://target.scene7.com/is/image/Target/GUEST_776d37b4-55a3-4cdd-93d6-c8d9233b332a?wid=1200&hei=1200&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_518c71a3-837e-434a-9104-9dc2171466c4?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_34465608-3e4a-433a-b150-267ae7d911e4?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_8d2c7470-0130-40ab-8313-28d376a46884?wid=800&hei=800&qlt=80&fmt=webp",
    ],
    description: {
      main: [
        "Gift your bath collection a touch of serene style and luxurious comfort by adding the Decorative Waffle Towel from Casaluna™. Available in neutral and soothing colors, it's easy to incorporate this textured waffle towel into a range of bath areas to suit your decor. The delicate waffle design creates a spa-inspired aesthetic to keep your bath space warm and welcoming, and it's made from 100% cotton with a washed finish for natural softness and long-lasting durability. This towel is thoughtfully designed to make your home an oasis of comfort and calm — combine it with other Casaluna bath items to curate a beautiful space you and guests alike will love. Due to the delicate nature of this towel, it may shrink slightly during washing and drying.",
        "This is your invitation to create a space that’s just for you. Make room to relax with Casaluna, where calm and comfort are naturally at home.",
        "We’re committed to making products better for you, and the world. This product is STANDARD 100 by OEKO-TEX® certified, meaning that everything from the dyes and fabrics to accessories like buttons and zippers are tested and verified as free from harmful levels of more than 300 substances.",
      ],
    },
    specification: {
      Dimensions: "56 Inches (L), 30 Inches (W)",
      Style: "Bath Towel",
      Weight: "",
      ProtectiveQualities: "",
      TextileMaterial: "100% Cotton",
      Includes: "",
      HangingStyle: "",
      FabricWeightType: " Lightweight (300-450 GSM)",
      NumbersofPiece: "",
      Sizing: "",
      Piece1: "",
      Piece1Material: "",
      Piece1GarmentLength: "",
      Piece2: "",
      Piece2Material: "",
      Piece2GarmentLength: "",
      Piece2GarmentClosure: "",
      Fit: "",
      SleeveLength: "",
      FabricName: "Waffle",
      Neckline: "",
      GarmentDetails: "",
      IndustryorGovernmentCertifications: "OEKO-TEX MADE IN GREEN",
      Piece1garmentclosuretype: "",
      OEKOTEXInstitution: "Hohenstein HTTI",
      OEKOTEXCertificationNumber: "M24M23FP4",
      CareandCleaning: "Machine Wash, Tumble Dry",
      TCIN: "86309174",
      UPC: "042075632656",
      ItemNumber: "064-18-0030",
      Origin: "Imported",
      FlameResistant: "",
      AdultGarment: "",
    },
  },
  {
    id: 3,
    name: "14pc Luxe Shower Curtain Set with Rug White - Threshold™: Includes Bath Mat, Buttonhole Top Design",
    like: "74",
    question: "1",
    prePrice: "25.00",
    price: "15.00",
    sale: "40%",
    size: [],
    color: [],
    images: [
      "https://target.scene7.com/is/image/Target/GUEST_b9a3f640-5a8c-46a2-8898-acb569916699?wid=1200&hei=1200&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_95166b8c-4993-4f67-a47a-bfde8ed34f24?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_ddeaf429-5968-43a2-9945-7652f664b5a4?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_fd873c4d-1f6e-4f79-86ef-803f599a224d?wid=800&hei=800&qlt=80&fmt=webp",
    ],
    description: {
      main: [
        "Add a touch of elegance to your bathroom decor with this 14-Piece Luxe Shower Curtain Set with Rug from Threshold™. This set includes one shower curtain, 12 shower curtain hooks and one bath rug to create a cohesive look. Crafted from soft microfiber, the shower curtain features an abstract print in cream, while the buttonhole top allows for easy installation. The bath rug in cream showcases a tufted design in a solid cream hue that feels soft and fluffy under your feet. A great pick for different decor styles, you can toss the shower curtain and the bath rug in the washing machine for a quick refresh whenever needed.",
        "Threshold™: Looks like home, feels like you.",
      ],
    },
    specification: {
      Dimensions: "72 Inches (H) x 72 Inches (W)",
      Style: "",
      Weight: "1 Pounds",
      ProtectiveQualities: "",
      TextileMaterial: "100% Microfiber",
      Includes: "Shower Curtain Hooks, Shower Curtain, Bath Rug",
      HangingStyle: "Buttonhole Top",
      FabricWeightType: "",
      NumbersofPiece: "",
      Sizing: "",
      Piece1: "",
      Piece1Material: "",
      Piece1GarmentLength: "",
      Piece2: "",
      Piece2Material: "",
      Piece2GarmentLength: "",
      Piece2GarmentClosure: "",
      Fit: "",
      SleeveLength: "",
      FabricName: "Microfiber",
      Neckline: "",
      GarmentDetails: "",
      IndustryorGovernmentCertifications: "",
      Piece1garmentclosuretype: "",
      OEKOTEXInstitution: "",
      OEKOTEXCertificationNumber: "",
      CareandCleaning: "Machine Wash, Drying Instructions Not Provided",
      TCIN: "87711609",
      UPC: "735732117251",
      ItemNumber: "064-18-3804",
      Origin: "Imported",
      FlameResistant: "",
      AdultGarment: "",
    },
  },
  {
    id: 4,
    name: "Girls' Hello Kitty Christmas 2pc Cozy Pajama Set and Socks - Pink",
    like: "11",
    question: "2",
    prePrice: "18.00",
    price: "12.60",
    sale: "30%",
    size: ["XS", "S", "M", "L"],
    color: [],
    images: [
      "https://target.scene7.com/is/image/Target/GUEST_73398c0a-ff53-44d5-ba4a-9019d1a2a693?wid=1200&hei=1200&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_6d6ce0cb-a14c-4ec4-a3cc-bec2ae440039?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_653be16a-d6f1-4276-b427-199e2756231c?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_f1ddb5f5-6fa0-4a46-8af1-a400f4448e03?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_b173e01d-9ebd-4c9f-b0e7-bbe95a36879b?wid=800&hei=800&qlt=80&fmt=webp",
    ],
    description: {
      main: [
        "Add some fun to your kids' PJ wardrobe with this Hello Kitty 2-Piece Cozy Pajama Set and Socks in pink. This pack includes a pair of socks, a long-sleeve sleep shirt in with allover Hello Kitty prints, and pajama pants with an elastic waistband and the same Hello Kitty prints. Made from jersey fleece fabric, this regular-fit pajama set is a must-have addition to your kid's PJ collection.",
      ],
    },
    specification: {
      Dimensions: "",
      Style: "",
      Weight: "",
      ProtectiveQualities: "",
      TextileMaterial: "",
      Includes: "",
      HangingStyle: "",
      FabricWeightType: "",
      NumbersofPiece: "2",
      Sizing: "Kids",
      Piece1: "Sleep Shirt",
      Piece1Material: "100% Polyester",
      Piece1GarmentLength: "",
      Piece2: "Pajama Pant",
      Piece2Material: "100% Polyester",
      Piece2GarmentLength: "Full",
      Piece2GarmentClosure: "Full Waistband Elastic",
      Fit: " Regular Fit",
      SleeveLength: "Long Sleeve",
      FabricName: "Fleece",
      Neckline: "Crew",
      GarmentDetails: "Full Waistband Elastic, Contrast Trim",
      IndustryorGovernmentCertifications: "",
      Piece1garmentclosuretype: "Pullover",
      OEKOTEXInstitution: "",
      OEKOTEXCertificationNumber: "",
      CareandCleaning: "Machine Wash, Tumble Dry",
      TCIN: "91188457",
      UPC: "198101668769",
      ItemNumber: "326-02-1525",
      Origin: "Imported",
      FlameResistant:
        "Flame resistant.If the item details above aren’t accurate or complete, we want to know about it. Report incorrect product info.",
      AdultGarment: "",
    },
  },
  {
    id: 5,
    name: "Performance Plus Bath Towel - Threshold™",
    like: "6252",
    question: "37",
    prePrice: "14.00",
    price: "8.40",
    sale: "40%",
    size: ["Oversized Bath Towel", "Bath Towel", "Hand Towel", "Washcloths"],
    color: [
      "Aqua",
      "Aqua Striped",
      "Black",
      "Black Striped",
      "Cream",
      "Cream Leopard",
      "Dark Gray",
      "Dark Gray Striped",
      "Dark Green",
      "Gray Floral",
      "Gray Geo",
      "Light Blue",
      "Light Blue Geo",
      "Light Gray",
      "Light Gray Leopard",
      "Light Green",
      "Light Green Floral",
      "Mauve",
      "Mauve Geo",
      "Navy",
      "Navy Striped",
      "Turquoise",
      "White",
      "White Striped",
    ],
    images: [
      "https://target.scene7.com/is/image/Target/GUEST_be9a4dc4-005b-401f-98be-b4882b604b18?wid=1200&hei=1200&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_b072a9ce-afc4-42db-8f45-43c96ee43586?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_1e38ef28-7cd5-4f61-a4ee-52388b80862d?wid=800&hei=800&qlt=80&fmt=webp",
      "https://target.scene7.com/is/image/Target/GUEST_7d815f75-a4f6-4752-b435-69d9e6ab49ff?wid=800&hei=800&qlt=80&fmt=webp",
    ],
    description: {
      main: [
        "Upgrade your bathroom towel collection with this Performance Plus 4pk washcloth set from Threshold™. This performance 4pk washcloth set made from heavyweight terry cotton fabric is super soft, highly absorbent and fast drying for comfortable and convenient use. The fade-resistant treatment helps prevent discoloration even after several washings.",
        "Threshold™: Looks like home, feels like you.",
      ],
    },
    specification: {
      Dimensions: "",
      Style: "Washcloth Set",
      Weight: "",
      ProtectiveQualities: "Fade-Resistant",
      TextileMaterial: "96% Cotton, 4% Polyester",
      Includes: "",
      HangingStyle: "",
      FabricWeightType: "Heavyweight (550-800 GSM)",
      NumbersofPiece: "4",
      Sizing: "",
      Piece1: "Washcloth: 13 Inches (L) x 13 Inches (W), Quantity 4",
      Piece1Material: "",
      Piece1GarmentLength: "",
      Piece2: "",
      Piece2Material: "",
      Piece2GarmentLength: "",
      Piece2GarmentClosure: "",
      Fit: "",
      SleeveLength: "",
      FabricName: "Terry",
      Neckline: "",
      GarmentDetails: "",
      IndustryorGovernmentCertifications: "OEKO-TEX MADE IN GREEN",
      Piece1garmentclosuretype: "",
      OEKOTEXInstitution: "TESTEX",
      OEKOTEXCertificationNumber: "SHHO 044376",
      CareandCleaning: "Machine Wash, Tumble Dry",
      TCIN: "87276473",
      UPC: "191908978543",
      ItemNumber: "064-09-2737",
      Origin: "Imported",
      FlameResistant: "",
      AdultGarment: "",
    },
  },
];

export default productData;
