import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";

function About() {
  return (
    <div className="main-container">
      <Header />

      <div className="about_box">
        <div className="about1_box">
          <div className="about1_box_inner">
            <div class="landing-page-hero--subheading-h3">About</div>
            <div className="about1_body">
              <span>
                We're here to help all families discover the joy of everyday
                life.
              </span>
            </div>
          </div>
        </div>

        <div className="about2_box">
          <div className="about2_box_inner">
            <h2>Explore the About Target section</h2>
            <div className="about2_inner_flex">
              <div className="about2_inner_flex_body">
                <div className="about2_internal">
                  <img alt="" src="/head/asset.png" />
                  <h3>Purpose & History</h3>
                  <div class="about2-card-cta--description ">
                    Who we are today is rooted in our company history and the
                    vision of our founders. Learn more, and meet the leadership
                    team who is driving Target into the future.
                  </div>
                </div>
              </div>
              <div className="about2_inner_flex_body">
                <div className="about2_internal">
                  <img alt="" src="/head/position.png" />
                  <h3>Our Locations</h3>
                  <div class="about2-card-cta--description ">
                    Learn more about the nearly 2,000 stores we operate, plus
                    our supply chain facilities and headquarters locations.
                  </div>
                </div>
              </div>
              <div className="about2_inner_flex_body">
                <div className="about2_internal">
                  <img alt="" src="/head/cart.png" />
                  <h3>Products & Services</h3>
                  <div class="about2-card-cta--description ">
                    From our exclusive only-at-Target offerings to the national
                    brands our guests expect, you can shop Target in stores,
                    online or delivered to your home.
                  </div>
                </div>
              </div>
              <div className="about2_inner_flex_body">
                <div className="about2_internal">
                  <img alt="" src="/head/custom.png" />
                  <h3>Contact & Help</h3>
                  <div class="about2-card-cta--description ">
                    Your one-stop-shop for key contact information for our
                    corporate teams and guest services.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about3_box">
          <div className="about_container">
            <h2>Fast facts about Target</h2>
            <div class="section--content flex-grid flex-row-gap-default ">
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    $107b{" "}
                  </div>

                  <div class="card-stat--description h3">
                    in total revenue, 2023
                  </div>
                </div>
              </div>
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    5%{" "}
                  </div>

                  <div class="card-stat--description h3">
                    of our profits go back into communities*
                  </div>
                </div>
              </div>
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    45+{" "}
                  </div>

                  <div class="card-stat--description h3">
                    owned brands unique to Target
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about4_box">
          <div className="about_container">
            <div class="section--header">
              <h2 class="section--header-title h2 text-left">Who we are</h2>
              <div class="section--header-description flex-grid ">
                <div class="flex-col flex-col-10--lg flex-col-8--xl">
                  <p>
                    Target Corporation also owns Shipt and Roundel. More to
                    love!
                  </p>
                </div>
              </div>
            </div>
            <div className="about4_flex">
              <div className="about4_flex_inner">
                <img
                  alt=""
                  src="https://corporate.target.com/getmedia/a19146a3-2115-4700-9bf9-17272dec8aee/target-logo.png?width=1144"
                />
              </div>
              <div className="about4_flex_inner">
                <img
                  alt=""
                  src="https://corporate.target.com/getmedia/071f5942-4462-4051-9897-e6c766f1ad8c/shipt-logo.png?width=1144"
                />
              </div>
              <div className="about4_flex_inner">
                <img
                  alt=""
                  src="https://corporate.target.com/getmedia/5b615e37-9dc5-4c12-998e-207a929a1243/roundel-logo.png?width=1144"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="about5_box">
          <h2>
            Target is a general&nbsp;
            <br />
            merchandise retailer with&nbsp;
            <br />
            stores in all 50 states and&nbsp;
            <br />
            the District of Columbia
          </h2>
        </div>

        <div className="about6_box">
          <div className="about_container">
            <div class="section--header">
              <h2 class="section--header-title h2 text-center">
                Our locations at a glance
              </h2>
            </div>
            <div class="section--content flex-grid flex-row-gap-default ">
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    1,963{" "}
                  </div>

                  <div class="card-stat--description h3">
                    stores in the U.S.
                  </div>
                </div>
              </div>
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    59{" "}
                  </div>

                  <div class="card-stat--description h3">
                    supply chain facilities
                  </div>
                </div>
              </div>
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    32{" "}
                  </div>

                  <div class="card-stat--description h3">
                    office locations globally
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about6_box" style={{ paddingTop: "0" }}>
          <div className="about_container">
            <div class="section--content flex-grid flex-row-gap-default flex-justify-content-center--xs ">
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    400k+{" "}
                  </div>

                  <div class="card-stat--description h3">
                    team members employed
                  </div>
                </div>
              </div>
              <div class="flex-col flex-col-4--md">
                <div class="card-stat style-option-0">
                  <div class="card-stat--value d2 text-color-red-shade">
                    Over 75%{" "}
                  </div>

                  <div class="card-stat--description h3">
                    of the U.S. population lives within 10 miles of a store
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="about7_box">
          <div className="about_container">
            <div class="section--footer">
              <div class="section--footer-content">
                <h2
                  class="text-color-red-shade d1"
                  id="isPasted"
                  style={{ textAlign: "center" }}
                >
                  Work somewhere you <span class="text-color-red">love</span>
                </h2>
                <p style={{ textAlign: "center" }}>
                  <br />
                </p>
                <p style={{ textAlign: "center" }}>
                  From stores to distribution centers, we're looking for new
                  team members like you
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about8_box">
          <div className="about_container">
            <div className="about8_flex">
              <img
                class="image--image"
                src="https://corporate.target.com/getmedia/db11bef5-ac6b-4cec-b844-e78f7e75e0c9/image-40.png?width=1144"
                alt=""
                loading="lazy"
              />
              <div className="about8_txt">
                <h2>Our mascot, Bullseye</h2>
                <p>
                  Bullseye has been our mascot since 1999. He made his debut in
                  Target's iconic "Sign of the Times" advertising campaign.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about9_box">
          <div className="about_container">
            <div class="section--header">
              <h2 class="section--header-title h2 text-left">
                Explore other areas of Target
              </h2>
            </div>
            <div class="section--content flex-grid flex-row-gap-default ">
              <div class="flex-col flex-col-4--md">
                <div class="card-cta-circle">
                  <div
                    class="card-cta-circle--icon-container theme-override-black-dark"
                    style={{ textAlign: "center" }}
                  >
                    <img alt="" src="/head/about1.png" />
                  </div>

                  <h3
                    class="card-cta-circle--title"
                    style={{ textAlign: "center" }}
                  >
                    Company News
                  </h3>
                </div>
              </div>
              <div class="flex-col flex-col-4--md">
                <div class="card-cta-circle">
                  <div
                    class="card-cta-circle--icon-container theme-override-black-dark"
                    style={{ textAlign: "center" }}
                  >
                    <img alt="" src="/head/about2.png" />
                  </div>

                  <h3
                    class="card-cta-circle--title"
                    style={{ textAlign: "center" }}
                  >
                    Investors &amp; Financials
                  </h3>
                </div>
              </div>
              <div class="flex-col flex-col-4--md">
                <div class="card-cta-circle">
                  <div
                    class="card-cta-circle--icon-container theme-override-black-dark"
                    style={{ textAlign: "center" }}
                  >
                    <img alt="" src="/head/about3.png" />
                  </div>

                  <h3
                    class="card-cta-circle--title"
                    style={{ textAlign: "center" }}
                  >
                    Sustainability &amp; Governance
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
